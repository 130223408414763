export const isValidCNPJ = cnpj => {
  cnpj = cnpj.replace(/\D/g, '');

  if (cnpj.length !== 14) {
    return false;
  }

  if (/^(\d)\1+$/.test(cnpj)) {
    return false;
  }

  let calc = 0;
  for (let i = 0; i < 12; i++) {
    calc += parseInt(cnpj[i]) * (5 - (i % 8));
  }
  let remainder = calc % 11;
  const firstDigit = remainder < 2 ? 0 : 11 - remainder;

  calc = 0;
  for (let i = 0; i < 13; i++) {
    calc += parseInt(cnpj[i]) * (6 - (i % 8));
  }
  remainder = calc % 11;
  const secondDigit = remainder < 2 ? 0 : 11 - remainder;

  return cnpj[12] === firstDigit && cnpj[13] === secondDigit;
};
