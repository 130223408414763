import React from 'react';
import { ActivityIndicator } from '@atomic/atm.activity-indicator';
import { Body } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { IconSize } from '@atomic/obj.constants';
import { FormContext } from '../form.context';
import { StepHeader, StepsHeaderWrapper } from './result/form-result.component.style';
import { StepsHeaderItem } from './steps-header.item';

export const StepsHeader = () => {
  const { formResult, strings, loadingCreateDraft, loadingUpdateDraft } = React.useContext(FormContext);
  return (
    <StepsHeaderWrapper>
      <StepsHeaderItem step={formResult?.CLIENT} />
      <StepsHeaderItem step={formResult?.DOCUMENT} />
      <StepsHeaderItem step={formResult?.REVISION} />
      <StepsHeaderItem step={formResult?.STEEL} />
      <StepsHeaderItem step={formResult?.PRODUCT} />
      <StepsHeaderItem step={formResult?.GOAL} />
      <StepsHeaderItem step={formResult?.ADDITIONAL} />

      {formResult && Object.keys(formResult).length === 1 && (
        <StepHeader key='NONE' percent={-1}>
          <Body>{strings.newForm.nextStepDetail}</Body>
        </StepHeader>
      )}
      {(loadingCreateDraft || loadingUpdateDraft) && (
        <Hbox vAlign='center'>
          <ActivityIndicator type='spinner' size={IconSize.Medium} />
        </Hbox>
      )}
    </StepsHeaderWrapper>
  );
};
