import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Button } from '@atomic/atm.button';
import { Body, H2 } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Col, Grid, Row, VSeparator } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
import { FormContext } from '../form.context';
import { FormStrings } from '../form.string';
import { resetChat } from '../utils/resetChat';

export const ModalRecoverDraft = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const [open, setOpened] = React.useState(false);
  const {
    handleGetDraft,
    setShowModalRecoverDraft,
    showModalRecoverDraft,
    draftQuote,
    setDraftQuote,
    setRecoveryMode,
    isFetchedFlowClient,
  } = React.useContext(FormContext);

  React.useEffect(() => {
    if (draftQuote?.idDraft) {
      setOpened(true);
    }
  }, [draftQuote]);

  const handleRemoveTempDraft = () => {
    localStorage.removeItem('draftQuote');
    setDraftQuote(null);
    setShowModalRecoverDraft(false);
    setRecoveryMode(false);
    resetChat();
  };

  const strings = FormStrings[userInfo.language].newForm.recoveryModal;

  return (
    <Modal preventOverlayClick opened={showModalRecoverDraft && open && isFetchedFlowClient} small customHeight='100'>
      <Grid fluid>
        <Row>
          <Col xs={12}>
            <H2>{strings.title}</H2>

            <Body>{strings.intro}</Body>
            <VSeparator />
            <VSeparator />
            <Hbox>
              <Hbox.Item grow>
                <Button expanded kind='secondary' onClick={() => handleRemoveTempDraft()}>
                  {strings.noButton}
                </Button>
              </Hbox.Item>
              <Hbox.Separator small />

              <Hbox.Item grow>
                <Button expanded kind='callToAction' type='submit' onClick={() => handleGetDraft(draftQuote?.idDraft)}>
                  {strings.yesButton}
                </Button>
              </Hbox.Item>
            </Hbox>
            <VSeparator />
            <VSeparator />
          </Col>
        </Row>
      </Grid>
    </Modal>
  );
};
