import { FieldsData } from './mapPayloadAnalysisOld.dto';

export function updateFormResult(response: { [key: string]: { questions: any } }[], draftDataSaved?: any) {
  const teste = response.map(stepCurrent => {
    const [stepKey, object] = Object.entries(stepCurrent)[0];

    const attributesFromDraft = draftDataSaved ? draftDataSaved.filter(item => item.step === stepKey) : [];

    const attributesFromStep = object?.questions?.fields ? Object.values(object.questions.fields) : [];

    const attributesFromDraftMap =
      attributesFromDraft?.reduce((acc, item) => {
        if (item.key !== undefined) {
          acc[item.key] = item;
          if (
            (item.key.includes('DIAMETER_') ||
              item.key.includes('THICKNESS_') ||
              item.key.includes('LENGTH_') ||
              item.key.includes('SIDE_') ||
              item.key.includes('AMOUNT_')) &&
            !item.key.includes('LENGTH_TOLERANCE')
          ) {
            acc[item.key].permissions = { visible: true, editable: true };
            acc[item.key].display = false;
            acc[item.key].userType = ['GERDAU', 'CLIENT'];
          }
          return acc;
        }
      }, {}) || {};

    const mergedAttributes = attributesFromStep.map((itemX: { key: string }) => {
      const itemY = attributesFromDraftMap[itemX.key];

      let val = null;

      if (itemY) {
        if (itemY.type === 'range') {
          val = itemY.value.split(' a ');
        }
        return {
          ...itemX,
          value: itemY.value,
          options: itemY.options,
          min: val ? val[0] : null,
          max: val ? val[1] : null,
          display: itemY.step === 'ADDITIONAL' ? true : object.questions.fields[itemY.key].display,
        };
      }
      return itemX;
    });

    const attributes = [
      ...mergedAttributes,
      ...attributesFromDraft.filter(item2 => !mergedAttributes.some(item1 => item1.key === item2.key)),
    ];

    const provState = {};
    attributes.forEach(item => {
      Object.assign(provState, {
        [item.key]: {
          ...item,
        },
      });
    });

    const newFormState = {
      [stepKey]: {
        ...object,
        questions: {
          ...object.questions,
          fields: {
            ...provState,
          },
        },
      },
    };

    return newFormState;
  });

  return teste.reduce((acc, item) => {
    acc = { ...acc, ...item };
    return acc;
  }, {});
}

export function updateAdditionalsFormResult(step, response: FieldsData) {
  const newFormState = {
    ADDITIONAL: {
      ...step,
      questions: {
        ...step.questions,
        fields: {
          ...step.questions.fields,
          [response.key]: response,
        },
      },
    },
  };

  return newFormState;
}
