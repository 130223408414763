/* eslint-disable complexity */
import React from 'react';
import { TagRadioField } from '@atomic/atm.tag-radio';
import { TextField } from '@atomic/atm.text-field';
import { Separator } from '@atomic/obj.box';
import { Form, Validators } from '@atomic/obj.form';
import { LoadingState } from '@atomic/obj.loading-state';
import { FormContext } from '../../form.context';
import { FormFlowProps, Options } from '../form-flow-fields.container';
import { FormResultItems } from '../result/form-result.component';
import { CustomFieldWrapper } from '../result/form-result.component.style';

export const RadioField = (props: FormFlowProps) => {
  const {
    userInfo,
    formResult,
    strings,
    loadingCallbackSteel,
    loadingCallbackChemicalComposition,
    loadingCallbackNorm,
    loadingFlowClient,
    loadingFlowClientManual,
    loadingFlowGoal,
    loadingFlowProduct,
    loadingFlowRevisionNorm,
    loadingFlowSteel,
    loadingFormFlowAdditional,
    openModals,
    loadingClientBps,
    setShowModalResetDraft,
    draftQuote,
  } = React.useContext(FormContext);
  const [filtredOptions, setFiltredOptions] = React.useState([]);

  const [otherValue, setOtherValue] = React.useState(false);
  const [isCustom, setIsCustom] = React.useState(false);

  React.useEffect(() => {
    checkOptions(props.field.options);
    if (formResult.STEEL?.questions?.fields?.SPECIFIC_SPECIFICATION_NAME?.file && props.field.key === 'STEEL_NAME') {
      setOtherValue(true);
    }
  }, [formResult]);

  const stringsNewForm = strings.newForm.messages;

  function checkOtherValue(value: string) {
    if (value === 'NONE') {
      openModals('CHEMICAL_COMPOSITION');
    } else if (value === 'OTHER') {
      if (props.field.key === 'STEEL_NAME') {
        setIsCustom(true);
      }
      if (props.field.key === 'SPECIFIC_SPECIFICATION') {
        props.handleValue(value, 'radio', props.field.key, props.stepKey);
        setOtherValue(false);
      } else {
        setOtherValue(true);
      }
    } else if (value === null) {
      setOtherValue(false);
      setIsCustom(false);
    } else if (props.field.key === 'FLOW') {
      if (draftQuote.idDraft && draftQuote.userId === userInfo._id) {
        setShowModalResetDraft(true);
      } else {
        setIsCustom(false);
        props.handleValue(value, 'radio', props.field.key, props.stepKey);
      }
    } else {
      //setOtherValue(false);
      setIsCustom(false);
      props.handleValue(value, 'radio', props.field.key, props.stepKey);
    }
  }

  const handleBlurField = (value: string) => {
    props.handleValue(value, 'text', props.field.key, props.stepKey);
  };

  function checkOptions(options: Options[]) {
    const ops = [];

    options &&
      options.forEach((op: Options) => {
        if (op?.conditional && op?.conditional?.length > 0) {
          const hasValidOption = op.conditional.filter(cond =>
            cond?.and?.length > 0
              ? (formResult[props.stepKey]?.questions?.fields[cond.key]?.value?.value === cond.value &&
                  formResult[props.stepKey]?.questions?.fields[cond.and[0].key]?.value?.value === cond.and[0].value) ||
                cond.key === 'AWAYS'
              : formResult[props.stepKey]?.questions?.fields[cond.key]?.value?.value === cond.value ||
                cond.key === 'AWAYS',
          );
          if (hasValidOption.length > 0) {
            ops.push(op);
          }
        }
      });

    const conjunto = new Set(ops);
    const filtredOps = Array.from(conjunto);
    const otherOption = filtredOps.find(op => op.key === 'OTHER' || op.key === 'NONE');
    const optionsWithoutOther = filtredOps.filter(op => op.key !== 'OTHER' && op.key !== 'NONE');

    optionsWithoutOther.sort((a, b) => {
      const labelA = a.label.trim().toLowerCase();
      const labelB = b.label.trim().toLowerCase();
      return labelA.localeCompare(labelB);
    });

    setFiltredOptions(otherOption !== undefined ? optionsWithoutOther.concat(otherOption) : optionsWithoutOther);
  }

  const checkValidator = (value: FormResultItems[], mandatory: boolean) => {
    if (mandatory) {
      if (value) {
        return [];
      }
      return [Validators.Required(stringsNewForm.mandatoryAlert)];
    } else {
      return [];
    }
  };

  let loading = false;
  if (props?.field.key === 'STEEL_NAME' || props?.field.key === 'STEEL_SEARCH') {
    loading = loadingCallbackSteel || loadingCallbackChemicalComposition;
  } else if (props?.field.key === 'SPECIFIC_SPECIFICATION') {
    loading = loadingCallbackNorm || loadingCallbackSteel;
  } else if (props?.field.key === 'BP') {
    loading = loadingClientBps;
  } else if (props?.field?.key === 'FLOW') {
    loading =
      loadingFlowClient ||
      loadingFlowClientManual ||
      loadingFlowGoal ||
      loadingFlowProduct ||
      loadingFlowRevisionNorm ||
      loadingFlowSteel ||
      loadingFormFlowAdditional ||
      loadingCallbackSteel;
  }

  return filtredOptions.length > 0 ? (
    <LoadingState loading={loading}>
      <Form.Field
        name={props.field.key}
        onValueChange={value => checkOtherValue(value)}
        validators={checkValidator(props.field?.value, props.field?.mandatory)}
        initialValue={formResult[props.stepKey]?.questions?.fields[props.field.key]?.value?.key}
      >
        {filtredOptions.map((option, index) => (
          <TagRadioField
            key={`${option.key}_${index}`}
            id={option.key}
            type='success'
            disabled={formResult[props.stepKey]?.questions?.fields[props.field.key]?.value?.key === option.key || false}
            onClick={() => setOtherValue(false)}
          >
            <span data-testid={`option${option.key}`}>{option.label}</span>
          </TagRadioField>
        ))}
      </Form.Field>
      {(otherValue || isCustom) && (
        <Form.Field
          name={props.field.key}
          validators={props?.field?.mandatory ? [Validators.Required(stringsNewForm.mandatoryAlert)] : []}
          initialValue={
            formResult !== null &&
            typeof formResult[props.stepKey]?.questions?.fields[props.field?.key]?.value === 'string'
              ? formResult[props.stepKey]?.questions?.fields[props.field?.key]?.value
              : null
          }
        >
          <Separator />
          <CustomFieldWrapper>
            <TextField
              placeholder={props?.field?.placeholder ? props.field.placeholder : stringsNewForm.otherFieldPlaceholder}
              id={props.field.key}
              onBlur={value => handleBlurField(value.target.value)}
            ></TextField>
          </CustomFieldWrapper>
        </Form.Field>
      )}
    </LoadingState>
  ) : null;
};
