/* eslint-disable complexity */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Element } from 'react-scroll';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { endpoint } from '@app/data/http';
import { validateTokens } from '@app/modules/components/notification-center/store/thunk/validate-and-get-notification.thunk';
import { Hbox } from '@atomic/obj.box';
import { FormContext } from '../form.context';
import { chemicalCompositionDefaultValue, conditionalAways } from '../utils/form.dto';
import { FieldsData, FormResultData } from '../utils/mapPayloadAnalysisOld.dto';
import { updateFormResult } from '../utils/updateFormResult';
import { FormFlowFieldsContainer } from './form-flow-fields.container';
import { FormSummary } from './formSummary';
import { DetailsWrapper } from './result/form-result.component.style';

interface FormFields {
  label?: string;
  typeField: string;
  question: string;
  detail?: string;
  placeholder?: string;
  key: string;
  mandatory?: boolean;
  display: boolean;
  conditional?: {
    key: string;
    conditionalValue: string;
  };
  options?: [
    {
      label: string;
      key: string;
    },
  ];
}
interface FormFlowStep {
  key: string;
  label: string;
  question: string;
  fields: FormFields[];
}
export interface FormFlowProps {
  formFlowData: { steps: FormFlowStep[] };
}

interface FormSectionProps {
  step: any;
  stepIndex: number;
  //loadings: boolean;
}

export const FormSection = (props: FormSectionProps) => {
  const { userInfo, azureToken, token } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch<AppThunkDispatch>();
  const {
    formResult,
    setFormResult,
    setInitialized,
    setFieldValue,
    postFile,
    callFlows,
    callBacksNorm,
    callBacksChemicalComposition,
    normInfos,
    normInfosSelected,
    setNormInfosSelected,
    callBacksBending,
    setCurrentFlow,
    currentFlow,
    checkRulesToDelete,
    openModals,
    callBacksSteel,
    steelSearchProv,
    setFileProv,
    setMarketClient,
    strings,
    handleUpdateDraft,
    draftQuote,
    setRecoveryMode,
  } = React.useContext(FormContext);

  const handleValue = React.useCallback(
    (
      value: string,
      typeField: string,
      keyField?: string,
      stepKey?: string,
      unit?: { symbol: string; description: string },
    ) => {
      setInitialized(true);

      let selectedValue;
      if (typeField === 'radio') {
        [selectedValue] = formResult[stepKey].questions.fields[keyField].options.filter(op => op.key === value);
      } else {
        selectedValue = value;
      }

      const field = [
        {
          ...formResult[stepKey].questions.fields[keyField],
          key: keyField,
          value: selectedValue,
          unit: unit,
          step: stepKey,
        },
      ];

      const updatedState: FormResultData = updateFormResult([{ [stepKey]: { ...formResult[stepKey] } }], field);

      if (keyField === 'CHECK_FILE') {
        //checkRulesToDelete(keyField);
        if (
          selectedValue.value === 'NO' &&
          formResult?.CLIENT?.questions?.fields?.FLOW?.value.value === 'NEW_PRODUCT'
        ) {
          if (userInfo.profile.profile !== 'client' && userInfo.profile.profile !== 'seller') {
            openModals('CHEMICAL_COMPOSITION');
          } else {
            setFormResult(prevValue => ({
              ...prevValue,
              STEEL: {
                ...prevValue.STEEL,
                questions: {
                  ...prevValue.STEEL.questions,
                  fields: {
                    ...prevValue.STEEL.questions.fields,
                    CHEMICAL_COMPOSITION: {
                      ...prevValue.STEEL.questions.fields.CHEMICAL_COMPOSITION,
                      value: chemicalCompositionDefaultValue,
                    },
                  },
                },
              },
            }));
          }
        }
      } else if (keyField === 'FLOW') {
        setCurrentFlow(value);
        callFlows(value);
      } else if (keyField === 'MARKET') {
        const market = value === 'EXPORT' ? 'ME' : 'MI';
        setMarketClient(market);
      } else if (keyField === 'NEW_CLIENT') {
        if (value === 'NO') {
          setFormResult(prevValue => ({
            ...prevValue,
            CLIENT: {
              ...prevValue.CLIENT,
              questions: {
                ...prevValue.CLIENT.questions,
                fields: {
                  ...prevValue.CLIENT.questions.fields,
                  FLOW: {
                    ...prevValue.CLIENT.questions.fields.FLOW,
                    value: null,
                  },
                  CLIENT_CUSTOM: {
                    ...prevValue.CLIENT.questions.fields.CLIENT_CUSTOM,
                    value: null,
                  },
                  CNPJ: {
                    ...prevValue.CLIENT.questions.fields.CNPJ,
                    value: null,
                  },
                },
              },
            },
          }));
        } else {
          setFieldValue('');
          setFormResult(prevValue => ({
            ...prevValue,
            CLIENT: {
              ...prevValue.CLIENT,
              questions: {
                ...prevValue.CLIENT.questions,
                fields: {
                  ...prevValue.CLIENT.questions.fields,
                  FLOW: {
                    ...prevValue.CLIENT.questions.fields.FLOW,
                    value: null,
                  },
                  CLIENT: {
                    ...prevValue.CLIENT.questions.fields.CLIENT,
                    value: null,
                  },
                  CLIENT_CODE: {
                    ...prevValue.CLIENT.questions.fields.CLIENT_CODE,
                    value: null,
                  },
                  CORPORATE_CODE: {
                    ...prevValue.CLIENT.questions.fields.CORPORATE_CODE,
                    value: null,
                  },
                },
              },
            },
          }));
        }
      } else if (keyField === 'CLIENT') {
        setFormResult(prevValue => ({
          ...prevValue,
          STEEL: {
            ...prevValue.STEEL,
            questions: {
              ...prevValue.STEEL.questions,
              fields: {
                ...prevValue.STEEL.questions.fields,
                FLOW: {
                  ...prevValue.STEEL.questions.fields.STEEL_NAME,
                  value: null,
                },
              },
            },
          },
        }));

        setFormResult(prevValue => ({
          ...prevValue,
          CLIENT: {
            ...prevValue.CLIENT,
            questions: {
              ...prevValue.CLIENT.questions,
              fields: {
                ...prevValue.CLIENT.questions.fields,
                FLOW: {
                  ...prevValue.CLIENT.questions.fields.FLOW,
                  display: false,
                  value: null,
                },
              },
            },
          },
        }));
      } else if (keyField === 'BP') {
        //const updatedState: FormResultData = formResult;
        updatedState[stepKey].questions.fields[keyField].value = selectedValue.value;
        updatedState[stepKey].questions.fields[keyField].unit = unit;
        updatedState[stepKey].questions.fields[keyField].step = stepKey;

        updatedState.CLIENT.questions.fields.CLIENT.value = selectedValue.value;
        updatedState.CLIENT.questions.fields.CLIENT.permissions.editable = false;

        updatedState.CLIENT.questions.fields.CLIENT_CODE.value = selectedValue.key;
        updatedState.CLIENT.questions.fields.CLIENT_CODE.permissions.editable = false;
        updatedState.CLIENT.questions.fields.FLOW.display = true;

        setFormResult(prevValue => ({
          ...prevValue,
          ...updatedState,
        }));

        if (formResult?.CLIENT?.questions?.fields?.FLOW.value?.value) {
          callFlows(formResult?.CLIENT?.questions?.fields?.FLOW.value?.value);
        }
      } else if (keyField === 'PRODUCT') {
        checkRulesToDelete('PRODUCT');
        updatedState.PRODUCT.questions.fields.SPECIFICATION_PRODUCT.value = {
          key: 'NO',
          label: 'Outro',
          value: 'NO',
        };
        setFormResult(prevValue => ({
          ...prevValue,
          ...updatedState,
        }));

        // setFormResult(prevValue => ({
        //   ...prevValue,
        //   PRODUCT: {
        //     ...prevValue.PRODUCT,
        //     questions: {
        //       ...prevValue.PRODUCT.questions,
        //       fields: {
        //         ...prevValue.PRODUCT.questions.fields,
        //         SPECIFICATION_PRODUCT: {
        //           ...prevValue.PRODUCT.questions.fields.SPECIFICATION_PRODUCT,
        //           value: {
        //             ...prevValue.PRODUCT.questions.fields.SPECIFICATION_PRODUCT.value,
        //             key: 'NO',
        //             label: 'Outro',
        //             value: 'NO',
        //           },
        //         },
        //       },
        //     },
        //   },
        // }));
      } else if (keyField === 'STEEL_NAME') {
        //checkRulesToDelete(keyField);
        if (typeField === 'radio') {
          dispatch(validateTokens()).then(() => {
            const payload = {
              steel: typeField === 'radio' ? selectedValue.label : selectedValue,
              corporateGroup: formResult.CLIENT.questions.fields.CORPORATE_CODE.value,
              field: 'SPECIFIC_SPECIFICATION',
              step: 'STEEL',
            };
            const url = formResult[stepKey].questions.fields[keyField].callback;
            callBacksNorm({
              payload,
              url,
              language: userInfo.language,
              action: 'getSpecificSpecificationList',
              authorizationToken: azureToken,
              accessToken: token,
            });
          });

          updatedState.STEEL.questions.fields.SPECIFIC_SPECIFICATION.display = true;
          updatedState.STEEL.questions.fields.STEEL_NAME.display = true;
          updatedState.STEEL.questions.fields.STEEL_NAME.value = selectedValue;
          updatedState.STEEL.questions.fields.STEEL_NAME.isCustom = false;
          updatedState.STEEL.questions.fields.NORM_INTERNATIONAL.display = false;

          setFormResult(prevValue => ({
            ...prevValue,
            ...updatedState,
          }));
        } else {
          if (value && currentFlow === 'NEW_PRODUCT') {
            dispatch(validateTokens()).then(() => {
              const payload = {
                field: 'STEEL_SEARCH',
                steel: value,
              };
              Object.assign(payload, { isCustom: true });
              const url = formResult.CLIENT.questions.fields.FLOW.callback;
              callBacksSteel({
                payload,
                url,
                action: 'getSteelList',
                language: userInfo.language,
                authorizationToken: azureToken,
                accessToken: token,
              });
            });

            formResult.STEEL.questions.fields.STEEL_NAME.value = value;

            setFormResult(prevValue => ({
              ...prevValue,
              STEEL: {
                ...prevValue.STEEL,
                questions: {
                  ...prevValue.STEEL.questions,
                  fields: {
                    ...prevValue.STEEL.questions.fields,
                    SPECIFIC_SPECIFICATION: {
                      ...prevValue.STEEL.questions.fields.SPECIFIC_SPECIFICATION,
                      display: false,
                      value: null,
                    },
                    NORM_INTERNATIONAL: {
                      ...prevValue.STEEL.questions.fields.NORM_INTERNATIONAL,
                      value: null,
                    },
                    STEEL_NAME: {
                      ...prevValue.STEEL.questions.fields.STEEL_NAME,
                      display: true,
                      value: value,
                    },
                  },
                },
              },
            }));
          }
        }
      } else if (keyField === 'NORM_INTERNATIONAL') {
        if (value === 'GENERAL_SPECIFICATION') {
          dispatch(validateTokens()).then(() => {
            const payload = {
              steel: steelSearchProv || formResult.STEEL.questions.fields.STEEL_NAME.value,
              field: 'CHEMICAL_COMPOSITION',
              step: 'STEEL',
            };
            const url = `${formResult.STEEL.questions.fields.CHEMICAL_COMPOSITION.callback}`;
            callBacksChemicalComposition({
              payload,
              url,
              action: 'getChemicalComposition',
              language: userInfo.language,
              authorizationToken: azureToken,
              accessToken: token,
            });
          });

          setFormResult(prevValue => ({
            ...prevValue,
            STEEL: {
              ...prevValue.STEEL,
              questions: {
                ...prevValue.STEEL.questions,
                fields: {
                  ...prevValue.STEEL.questions.fields,
                  SPECIFIC_SPECIFICATION_REVISION_NORM: {
                    ...prevValue.STEEL.questions.fields.SPECIFIC_SPECIFICATION_REVISION_NORM,
                    display: false,
                    value: null,
                  },
                  SPECIFICATION_REVISION: {
                    ...prevValue.STEEL.questions.fields.SPECIFICATION_REVISION,
                    display: false,
                    value: null,
                  },
                  SPECIFICATION_DATE: {
                    ...prevValue.STEEL.questions.fields.SPECIFICATION_DATE,
                    display: false,
                    value: null,
                  },
                  CHECK_FILE: {
                    ...prevValue.STEEL.questions.fields.CHECK_FILE,
                    value: null,
                  },
                  NORM_INTERNATIONAL: {
                    ...prevValue.STEEL.questions.fields.NORM_INTERNATIONAL,
                    value: selectedValue,
                    permissions: { visible: true, editable: true },
                  },
                },
              },
            },
          }));
        } else {
          setFormResult(prevValue => ({
            ...prevValue,
            STEEL: {
              ...prevValue.STEEL,
              questions: {
                ...prevValue.STEEL.questions,
                fields: {
                  ...prevValue.STEEL.questions.fields,
                  CHEMICAL_COMPOSITION: {
                    ...prevValue.STEEL.questions.fields.CHEMICAL_COMPOSITION,
                    value: null,
                    display: false,
                  },
                  CHECK_FILE: {
                    ...prevValue.STEEL.questions.fields.CHECK_FILE,
                    value: null,
                  },
                },
              },
            },
          }));
        }
        setFormResult(prevValue => ({
          ...prevValue,
          STEEL: {
            ...prevValue.STEEL,
            questions: {
              ...prevValue.STEEL.questions,
              fields: {
                ...prevValue.STEEL.questions.fields,
                NORM_INTERNATIONAL: {
                  ...prevValue.STEEL.questions.fields.NORM_INTERNATIONAL,
                  value: selectedValue,
                },
                SPECIFIC_SPECIFICATION: {
                  ...prevValue.STEEL.questions.fields.SPECIFIC_SPECIFICATION,
                  value: null,
                  display: false,
                },
              },
            },
          },
        }));
      } else if (keyField === 'SPECIFIC_SPECIFICATION') {
        if (value !== 'OTHER') {
          const selectedAttributes = normInfos.filter(subArray =>
            subArray.some(item => item.questionKey === 'SPECIFIC_SPECIFICATION' && item.options[0].key === value),
          );

          const valueNorm =
            selectedAttributes.length > 0
              ? selectedAttributes[0].filter(
                  item => item.questionKey === 'SPECIFIC_SPECIFICATION' && item.options[0].key === value,
                )
              : [];

          const transformProductsOptions = (dataArray, prod) => {
            const keys = [];
            const labels = [];

            dataArray.forEach(item => {
              if (item.questionKey === 'PRODUCT') {
                prod = item.value.value;
                keys.push(item.value.key);
                labels.push(item.value.label);
              } else if (
                item.questionKey === 'SURFACE_FINISH' ||
                item.questionKey === `HEAT_TREATMENT_${prod}` ||
                item.questionKey === `SHAPE_${prod}`
              ) {
                keys.push(item.value.key);
                labels.push(item.value.label);
              }
            });

            return {
              key: keys.join('|'),
              label: labels.join(' - '),
              conditional: conditionalAways,
            };
          };

          const specificationProductOptions = [];
          selectedAttributes.forEach((item, indexPosition) => {
            const prod = item[4].value.value;
            specificationProductOptions.push({ ...transformProductsOptions(item, prod), value: indexPosition });
          });

          specificationProductOptions.push({
            key: 'NO',
            label: strings.newForm.otherOptionButton,
            value: 'NO',
            conditional: conditionalAways,
          });

          if (selectedAttributes.length > 0) {
            setNormInfosSelected(selectedAttributes);
          }

          formResult.PRODUCT.questions.fields.SPECIFICATION_PRODUCT.options = specificationProductOptions;
          formResult.PRODUCT.questions.fields.SPECIFICATION_PRODUCT.display = true;
          // updatedState.PRODUCT.questions.fields.SPECIFICATION_PRODUCT.options = specificationProductOptions;
          // updatedState.PRODUCT.questions.fields.SPECIFICATION_PRODUCT.display = true;

          selectedAttributes[0].forEach((op: { questionKey: string; value?: any; options?: any }) => {
            if (
              op.questionKey === 'SPECIFIC_SPECIFICATION' ||
              op.questionKey === 'SPECIFIC_SPECIFICATION_REVISION_NORM' ||
              op.questionKey === 'SPECIFICATION_REVISION' ||
              op.questionKey === 'SPECIFICATION_DATE'
            ) {
              formResult.STEEL.questions.fields[op.questionKey].value = op.value;
              updatedState.STEEL.questions.fields[op.questionKey].value = op.value;
            }
          });
          dispatch(validateTokens()).then(() => {
            const payload = {
              specification: valueNorm.length > 0 ? valueNorm[0]?.options[0]?.value : value,
              steel: formResult.STEEL.questions.fields.STEEL_NAME.value.label,
              field: 'CHEMICAL_COMPOSITION',
              step: 'STEEL',
            };
            const url = `${formResult.STEEL.questions.fields.CHEMICAL_COMPOSITION.callback}`;
            callBacksChemicalComposition({
              payload,
              url,
              action: 'getChemicalComposition',
              language: userInfo.language,
              authorizationToken: azureToken,
              accessToken: token,
            });
          });
        }
      } else if (keyField === 'CHECK_FILE_REVISION_NORM') {
        updatedState.REVISION.questions.fields.SPECIFICATION_REVISION_REVISION_NORM.value = null;
        updatedState.REVISION.questions.fields.SPECIFICATION_DATE_REVISION_NORM.value = null;
        updatedState.REVISION.questions.fields.SPECIFIC_SPECIFICATION_REVISION_NORM.value = null;
        updatedState.REVISION.questions.fields.SPECIFIC_SPECIFICATION_NAME_REVISION_NORM.value = null;
      } else if (keyField === 'SPECIFICATION_PRODUCT') {
        checkRulesToDelete('PRODUCT');
        if (selectedValue.value !== 'NO') {
          normInfosSelected[selectedValue.value].forEach((op: { questionKey: string; value?: any; options?: any }) => {
            if (
              op.questionKey !== 'SPECIFIC_SPECIFICATION' &&
              op.questionKey !== 'SPECIFIC_SPECIFICATION_REVISION_NORM' &&
              op.questionKey !== 'SPECIFICATION_REVISION' &&
              op.questionKey !== 'SPECIFICATION_DATE'
            ) {
              updatedState.PRODUCT.questions.fields[op.questionKey].value = op.value;
              updatedState.PRODUCT.questions.fields[op.questionKey].display = true;

              setFormResult(prevValue => ({
                ...prevValue,
                ...updatedState,
              }));
            }
          });
        }
      } else if (
        keyField === 'SURFACE_FINISH' ||
        keyField === 'SHAPE_BAR' ||
        keyField === 'PRODUCT' ||
        keyField === 'GERDAU_PROCESS_BAR' ||
        keyField === 'DIAMETER'
      ) {
        const surfaceFinish = formResult?.PRODUCT?.questions?.fields?.SURFACE_FINISH?.value?.key;
        const shape = formResult?.PRODUCT?.questions?.fields?.SHAPE_BAR?.value?.key;
        const product = formResult?.PRODUCT?.questions?.fields?.PRODUCT?.value?.key;
        const gerdauProcess = formResult?.PRODUCT?.questions?.fields?.GERDAU_PROCESS_BAR?.value?.key;
        const diameter = formResult?.PRODUCT?.questions?.fields?.DIAMETER?.value;

        if (surfaceFinish && shape && product && gerdauProcess && diameter) {
          dispatch(validateTokens()).then(() => {
            const payload = {
              PRODUCT: product,
              SHAPE: shape,
              GERDAU_PROCESS: gerdauProcess,
              SURFACE_FINISH: surfaceFinish,
              CLASS_TOLERANCE: 'TOL_NORMAL',
              DIAMETER: parseFloat(diameter),
            };
            const url = `${endpoint.FORM}/data/product-standard`;
            callBacksBending({
              payload,
              url,
              action: 'getBending',
              language: userInfo.language,
              authorizationToken: azureToken,
              accessToken: token,
            });
          });
        }
      }

      setFormResult(prevValue => ({
        ...prevValue,
        ...updatedState,
      }));

      if (stepKey === 'ADDITIONAL') {
        handleUpdateDraft(draftQuote.idDraft, field);
      }
      setRecoveryMode(false);
    },
    [formResult, setFormResult],
  );

  const handleUpload = (file: File[], label?: string, stepKey?: string) => {
    setFileProv(file[0]);
    setRecoveryMode(false);
    setFormResult(prevValue => ({
      ...prevValue,
      [stepKey]: {
        ...prevValue[stepKey],
        questions: {
          ...prevValue[stepKey]?.questions,
          fields: {
            ...prevValue[stepKey]?.questions.fields,
            SPECIFIC_SPECIFICATION_NAME: {
              ...prevValue[stepKey].questions.fields.SPECIFIC_SPECIFICATION_NAME,
              file: file,
              label: label,
              value: file[0].name,
              type: 'file',
            },
            SPECIFIC_SPECIFICATION_NAME_REVISION_NORM: {
              ...prevValue[stepKey].questions.fields.SPECIFIC_SPECIFICATION_NAME_REVISION_NORM,
              file: file,
              label: label,
              value: file[0].name,
              type: 'file',
            },
            SPECIFIC_SPECIFICATION_NAME_CLIENT_MANUAL: {
              ...prevValue[stepKey].questions.fields.SPECIFIC_SPECIFICATION_NAME_CLIENT_MANUAL,
              file: file,
              label: label,
              value: file[0].name,
              type: 'file',
            },
          },
        },
      },
    }));
    dispatch(validateTokens()).then(() => {
      postFile({
        flow: formResult.CLIENT.questions.fields.FLOW.value.key,
        file: file[0],
        language: userInfo.language,
        authorizationToken: azureToken,
        accessToken: token,
      });
    });
  };

  const hasAttributeToShow = props.step?.questions?.fields
    ? Object.entries(props.step?.questions?.fields).filter((field: [string, FieldsData]) => field[1].display)
    : [];

  return props.step && JSON.stringify(props.step?.questions?.fields) !== '{}' && hasAttributeToShow.length > 0 ? (
    <Element name={props.step?.questions?.key} key={props.step?.questions?.key}>
      <DetailsWrapper id={props.step?.questions?.key} bg={true} open={props.stepIndex === 0 || false}>
        {props.step?.questions?.fields !== undefined && (
          <>
            <FormSummary
              stepIndex={props.stepIndex}
              stepKey={props.step?.questions?.key}
              stepLabel={props.step?.questions?.label}
              stepQuestion={props.step?.questions?.question}
              steps={props.step?.questions?.fields && Object.values(props.step?.questions?.fields)}
            />

            <Hbox.Item>
              {Object.entries(props.step?.questions?.fields).map((field: [string, FieldsData], index: number) => {
                const [key, object] = field;
                return (
                  <FormFlowFieldsContainer
                    flow={formResult?.CLIENT?.questions?.fields?.FLOW?.value?.value}
                    key={`${key}-${index}`}
                    stepKey={props.step?.questions.key}
                    field={object}
                    handleUpload={handleUpload}
                    handleValue={handleValue}
                    tabIndex={index}
                  />
                );
              })}
            </Hbox.Item>
          </>
        )}
      </DetailsWrapper>
    </Element>
  ) : (
    <></>
  );
};
