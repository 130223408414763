import styled, { css } from 'styled-components';
import { Color, FontFamily } from '@atomic/obj.constants';

const RangeWidthCSS = css`
  width: 250px;
`;

const RoundedCSS = css`
  border-radius: 3px;
  height: 3px;
`;

const PositionAbsoluteCSS = css`
  position: absolute;
`;

const FormatValueCSS = css`
  font-size: 12px;
  margin-top: 20px;
  font-family: ${FontFamily.Primary};
`;

const ThumbCSS = css`
  ${RangeWidthCSS};
  pointer-events: none;
  position: absolute;
  height: 0;
  outline: none;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;

  &::-webkit-slider-thumb {
    background-color: ${({ disabled }) => (disabled ? Color.GrayLight : Color.Primary)};
    border: none;
    border-radius: 50%;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
  }

  &:-moz-range-thumb {
    border: none;
    border-radius: 50%;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }
`;

export const RangeWrapper = styled.div`
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Slider = styled.div`
  ${RangeWidthCSS};
  ${PositionAbsoluteCSS};
`;

export const SlidarTrack = styled.div`
  ${PositionAbsoluteCSS};
  ${RoundedCSS};
  height: 3px;
  background-color: ${Color.Gray};
  width: 98%;
  z-index: 1;
  pointer-events: none;
`;

export const SliderRange = styled.div`
  ${PositionAbsoluteCSS};
  ${RoundedCSS};
  background-color: ${({ disabled }) => (disabled ? Color.GrayLight : Color.Primary)};
  z-index: 2;
  max-width: 98%;
  pointer-events: none;
`;

export const SliderLeftValue = styled.div`
  ${PositionAbsoluteCSS};
  ${FormatValueCSS};
  left: 2px;
`;

export const SliderRightValue = styled.div`
  ${PositionAbsoluteCSS};
  ${FormatValueCSS};
  right: 2px;
`;

export const InputRangeMin = styled.input`
  ${ThumbCSS};
  z-index: ${props => (props.minVal > props.max ? 3 : 5)};
`;

export const InputRangeMax = styled.input`
  ${ThumbCSS};
  z-index: 4;
`;
