export const QuoteStrings = {
  pt: {
    tabs: {
      all: 'Todas',
      myAnalysis: 'Minhas pendências',
      myClients: 'Meus clientes',
      draft: 'Rascunhos',
      crm: 'CRM',
    },
    basicOption: {
      yes: 'Sim',
      no: 'Não',
    },
    prep: 'a',
    yourAnalysis: 'Suas consultas',
    newAnalysis: 'Nova consulta',
    analysisFilter: 'Filtro de consultas',
    myAnalysis: 'Minhas pendências',
    myClients: 'Meus clientes',
    allAnalysis: 'Todas as consultas',
    dateFilter: {
      initial: 'Filtrar por período',
      custom: 'Personalizar',
      from: 'De',
      to: 'Até',
      back: 'Voltar',
      apply: 'Aplicar',
      LastMonth: 'Últimos 30 dias',
      LastTwoMonths: 'Últimos 60 dias',
      LastQuarter: 'Últimos 90 dias',
      All: 'Todo o histórico',
      Custom: 'Personalizar...',
      orderBy: 'Ordenar por ',
      desc: 'mais recentes',
      asc: 'mais antigos',
      time: 'prazo de vencimento',
    },
    filterModal: {
      clientField: 'Clientes',
      searchFilter: 'Filtro de buscas',
      research: 'Pesquisar',
      filterReset: 'Limpar filtros',
      filterShow: 'Carregar mais filtros',
      filterHide: 'Ocultar filtros',
    },
    components: {
      previewWarning: 'O resultado obtido representa uma pré-analise da sua consulta.',
      price: 'Preço disponível',
      legacyId: (legacyId: string) => `(Legado: ${legacyId})`,
      previewCanceled: 'Motivo do cancelamento: ',
      noCanceledMessage: 'não informado.',
      previewCanceledRequest: 'Solicitação de cancelamento: ',
      tagsLabel: {
        clientVisible: 'Visível para o cliente',
        clientCreator: 'Criada pelo cliente',
      },
      createOrderButton: 'Criar pedido',
    },
    constants: {
      status: {
        key: {
          derogate: 'derogate',
          accepted: 'accepted',
          noStatus: 'non-status',
          notAnalysed: 'non-analysed',
          pendingAnalysis: 'pending-analysis',
          error: 'erro',
        },
        label: {
          derogate: 'Derroga',
          accepted: 'Aceito',
          noStatus: 'Sem status',
          notAnalysed: 'Não analisada',
          pendingAnalysis: 'Pendente de análise',
          error: 'Erro',
        },
      },
      statusAttributes: {
        accepted: 'Aceito',
        derogate: 'Derroga',
        'non-analysed': 'Não analisada',
        'non-status': 'Sem status',
        'pending-analysis': 'Pendente de análise',
        error: 'Erro',
      },
      internationalStandard: 'Norma Internacional',
      stage: {
        key: {
          processing: 'PROCESSING',
          internalQuery: 'INTERNAL_QUERY',
          underAnalysisEpp: 'UNDER_ANALYSIS_EPP',
          underAnalysisPed: 'UNDER_ANALYSIS_PED',
          underAnalysisRtc: 'UNDER_ANALYSIS_RTC',
          dataValidationRtc: 'DATA_VALIDATION_RTC',
          inValidationEpp: 'IN_VALIDATION_EPP',
          inValidationPed: 'IN_VALIDATION_PED',
          waitingClientReview: 'WAITING_CLIENT_REVIEW',
          pending: 'PENDING',
          underAnalysis: 'UNDER_ANALYSIS',
          inValidation: 'IN_VALIDATION',
          canceled: 'CANCELED',
          rejected: 'REJECTED',
          finalized: 'FINALIZED',
          finalizedWithDerogation: 'FINALIZED_WITH_DEROGATION',
          waitingPrice: 'WAITING_PRICE',
          priceAvailable: 'AVAILABLE_PRICE',
          internalQuerySeller: 'INTERNAL_QUERY_SELLER',
          validationRTC: 'VALIDATION_RTC',
          validationSeller: 'VALIDATION_SELLER',
          clientEdition: 'CLIENT_EDITION',
          underAnalysisGq: 'UNDER_ANALYSIS_GQ',
          inValidationGq: 'IN_VALIDATION_GQ',
          waitingReview: 'WAITING_CLIENT_REVIEW_CLIENT',
          noCustomerResponse: 'NO_CUSTOMER_RESPONSE',
        },
        label: {
          PROCESSING: 'Processando',
          INTERNAL_QUERY: 'Consulta interna',
          UNDER_ANALYSIS_EPP: 'Em análise EPP',
          UNDER_ANALYSIS_PED: 'Em análise P&D',
          UNDER_ANALYSIS_RTC: 'Em análise RTC',
          DATA_VALIDATION_RTC: 'Correção de dados (RTC)',
          IN_VALIDATION_EPP: 'Em validação (EPP)',
          IN_VALIDATION_PED: 'Em validação (P&D)',
          WAITING_CLIENT_REVIEW: 'Aguardando parecer cliente',
          PENDING: 'Pendente de envio',
          UNDER_ANALYSIS: 'Em análise',
          IN_VALIDATION: 'Em validação',
          WAITING_CLIENT_REVIEW_CLIENT: 'Aguardando parecer',
          CANCELED: 'Cancelada',
          REJECTED: 'Rejeitada',
          FINALIZED: 'Finalizada',
          FINALIZED_WITH_DEROGATION: 'Finalizado com derroga',
          WAITING_PRICE: 'Aguardando Cotação',
          AVAILABLE_PRICE: 'Cotação Disponível',
          INTERNAL_QUERY_SELLER: 'Consulta vendedor(a)',
          VALIDATION_RTC: 'Validação RTC',
          VALIDATION_SELLER: 'Validação vendedor(a)',
          CLIENT_EDITION: 'Edição Cliente',
          UNDER_ANALYSIS_GQ: 'Em Análise GQ',
          IN_VALIDATION_GQ: 'Em Validação (GQ)',
          NO_CUSTOMER_RESPONSE: 'Sem retorno do cliente',
        },
      },
    },
    placeholder: {
      noData: 'Não há consultas disponíveis para este filtro',
      noDataMyPendings: 'Não há pendências para você com os filtros que foram selecionados',
    },
    defaultMessageGerdau: {
      chatResult: '* Característica automaticamente preenchida com padrão de fornecimento da Gerdau',
      chatResultShort: '* Padrão de fornecimento da Gerdau.',
      chatResultGerdauAdvice: ' *',
      chatResultNormAdvice: ' (lido pela norma)',
      tootipChatResult: 'Característica automaticamente preenchida',
      analysisPage: '* Padrão de fornecimento da Gerdau',
    },
    tooltips: {
      draft: {
        title: '',
        description: 'Clicando em "Rascunhos", você pode recuperar consultas que não foram finalizadas na ISA.',
      },
      myPending: {
        title: '',
        description: 'Agora você pode conferir suas consultas pendentes clicando em "Minhas pendências".',
      },
      clientVisible: {
        step1: {
          description: 'Clique para selecionar os itens que serão exibidos para o Cliente.',
          step: 'Passo: 1 de 2',
          btn: 'Próximo',
        },
        step2: {
          description: 'Clique para efetivar a ação de Exibir ou Ocultar os itens que você selecionou.',
          step: 'Passo: 2 de 2',
          btn: 'Fechar',
        },
      },
    },
    headerListSelect: {
      labelQuote: 'Selecionar itens para o Cliente',
      labelDraft: 'Selecionar itens',
      btnShow: 'Exibir selecionados',
      btnHide: 'Ocultar selecionados',
      btnRemove: 'Remover',
      btnCancel: 'Cancelar',
      btnRemoveSelected: 'Remover selecionados',
    },
    draft: 'Rascunho',
    timeLimit: {
      tomorrow: 'Seu prazo: vence amanhã',
      today: 'Seu prazo: vence hoje',
      deadline: 'Seu prazo: vence',
      expired: 'Seu prazo: vencido',
      date: 'Data de abertura:',
    },
    pagination: {
      next: 'Próximo',
      prev: 'Anterior',
    },
    draftPage: {
      removeBtn: 'Remover',
      cancelBtn: 'Cancelar',
    },
    kanbanLink: {
      kanban: 'Kanban',
      list: 'Exibir em lista',
    },
    newFormRevision: 'Abrir nova revisão de norma',
    newForm: 'Nova consulta formulário',
  },
  in: {
    tabs: {
      all: 'All',
      myAnalysis: 'My pendings',
      myClients: 'My clients',
      draft: 'Drafts',
      crm: 'CRM',
    },
    basicOption: {
      yes: 'Yes',
      no: 'No',
    },
    prep: 'to',
    yourAnalysis: 'Your quotes',
    newAnalysis: 'New quotation',
    analysisFilter: 'Quotation filter',
    myAnalysis: 'My pending',
    myClients: 'My clients',
    allAnalysis: 'All Inquiries',
    dateFilter: {
      initial: 'Filter by period',
      custom: 'Personalize',
      from: 'from',
      to: 'Until',
      back: 'Back',
      apply: 'Apply',
      LastMonth: 'Last 30 days',
      LastTwoMonths: 'Last 60 days',
      LastQuarter: 'Last 90 days',
      All: 'All history',
      Custom: 'Personalize...',
      orderBy: 'Sort by: ',
      desc: 'newest',
      asc: 'oldest',
      time: 'expiration date',
    },
    filterModal: {
      clientField: 'Clients',
      searchFilter: 'Search filter',
      research: 'Search',
      filterReset: 'Clear filters',
      filterShow: 'Load more filters',
      filterHide: 'Hide filters',
    },
    components: {
      previewWarning: 'The result obtained represents a pre-analysis of your quotation.',
      price: 'Available price',
      legacyId: (legacyId: string) => `(Legacy: ${legacyId})`,
      previewCanceled: 'Reason for cancellation: ',
      noCanceledMessage: 'uninformed.',
      previewCanceledRequest: 'Cancellation request: ',
      tagsLabel: {
        clientVisible: 'Visible to the client',
        clientCreator: 'Created by client',
      },
      createOrderButton: 'Crear orden',
    },
    constants: {
      status: {
        key: {
          derogate: 'derogate',
          accepted: 'accepted',
          noStatus: 'non-status',
          notAnalysed: 'non-analysed',
        },
        label: {
          derogate: 'Exception',
          accepted: 'Accepted',
          noStatus: 'Without status',
          notAnalysed: 'Not analyzed',
        },
      },
      statusAttributes: {
        accepted: 'Accepted',
        derogate: 'Exception',
        'non-analysed': 'Not analyzed',
        'non-status': 'Without status',
        'pending-analysis': 'Pending analysis',
        error: 'Error',
      },
      internationalStandard: 'International Norm',
      stage: {
        key: {
          processing: 'PROCESSING',
          internalQuery: 'INTERNAL_QUERY',
          underAnalysisEpp: 'UNDER_ANALYSIS_EPP',
          underAnalysisPed: 'UNDER_ANALYSIS_PED',
          underAnalysisRtc: 'UNDER_ANALYSIS_RTC',
          dataValidationRtc: 'DATA_VALIDATION_RTC',
          inValidationEpp: 'IN_VALIDATION_EPP',
          inValidationPed: 'IN_VALIDATION_PED',
          waitingClientReview: 'WAITING_CLIENT_REVIEW',
          pending: 'PENDING',
          underAnalysis: 'UNDER_ANALYSIS',
          inValidation: 'IN_VALIDATION',
          canceled: 'CANCELED',
          rejected: 'REJECTED',
          finalized: 'FINALIZED',
          finalizedWithDerogation: 'FINALIZED_WITH_DEROGATION',
          waitingPrice: 'WAITING_PRICE',
          priceAvailable: 'AVAILABLE_PRICE',
          internalQuerySeller: 'INTERNAL_QUERY_SELLER',
          validationRTC: 'VALIDATION_RTC',
          validationSeller: 'VALIDATION_SELLER',
          clientEdition: 'CLIENT_EDITION',
          underAnalysisGq: 'UNDER_ANALYSIS_GQ',
          inValidationGq: 'IN_VALIDATION_GQ',
          waitingReview: 'WAITING_CLIENT_REVIEW_CLIENT',
          noCustomerResponse: 'NO_CUSTOMER_RESPONSE',
        },
        label: {
          PROCESSING: 'Processing',
          INTERNAL_QUERY: 'Internal quotation',
          UNDER_ANALYSIS_EPP: 'Under review EPP',
          UNDER_ANALYSIS_PED: 'Under review R&D',
          UNDER_ANALYSIS_RTC: 'Under review RTC',
          DATA_VALIDATION_RTC: 'Data Correction (RTC)',
          IN_VALIDATION_EPP: 'In validation (EPP)',
          IN_VALIDATION_PED: 'In validation (R&D)',
          WAITING_CLIENT_REVIEW: 'Customer response',
          PENDING: 'Need to send',
          UNDER_ANALYSIS: 'Under analysis',
          IN_VALIDATION: 'In validation',
          WAITING_CLIENT_REVIEW_CLIENT: 'Waiting for response',
          CANCELED: 'Canceled',
          REJECTED: 'Rejected',
          FINALIZED: 'Finished',
          FINALIZED_WITH_DEROGATION: 'Finished with exception',
          WAITING_PRICE: 'Awaiting quotation',
          AVAILABLE_PRICE: 'Quotation Available',
          INTERNAL_QUERY_SELLER: 'Seller quotation',
          VALIDATION_RTC: 'RTC Validation',
          VALIDATION_SELLER: 'Seller validation',
          CLIENT_EDITION: 'Customer Edition',
          UNDER_ANALYSIS_GQ: 'Under review  GQ',
          IN_VALIDATION_GQ: 'In validation (GQ)',
          NO_CUSTOMER_RESPONSE: 'No customer response',
        },
      },
    },
    placeholder: {
      noData: 'There is no quotation available for this filter',
      noDataMyPendings: 'There are no pendencies for you with the selected filters',
    },
    defaultMessageGerdau: {
      chatResult: '* Characteristic automatically filled in with Gerdau supply standard',
      chatResultShort: '* Gerdau supply standard.',
      chatResultGerdauAdvice: ' *',
      chatResultNormAdvice: ' (read by the norm)',
      tootipChatResult: 'read by the norm',
      analysisPage: '* Gerdau supply standard',
    },
    tooltips: {
      draft: {
        title: '',
        description: 'By clicking on "Drafts", you can retrieve queries that were not finished in ISA.',
      },
      myPending: {
        title: '',
        description: 'You can now check your pending queries by clicking on "My Pending"',
      },
      clientVisible: {
        step1: {
          description: 'Click to select the items that will be displayed to the Client.',
          step: 'Step: 1 of 2',
          btn: 'Next',
        },
        step2: {
          description: 'Click to Show or Hide the items you selected.',
          step: 'Step: 2 of 2',
          btn: 'Close',
        },
      },
    },
    headerListSelect: {
      labelQuote: 'Select items for the Client',
      labelDraft: 'Select items',
      btnShow: 'Show selected',
      btnHide: 'Hide selected',
      btnRemove: 'Remove',
      btnCancel: 'Cancel',
      btnRemoveSelected: 'Remove selected',
    },
    draft: 'Draft',
    timeLimit: {
      tomorrow: 'Your deadline: expires tomorrow',
      today: 'Your deadline: expires today',
      deadline: 'Your deadline: expires',
      expired: 'Your deadline: expired',
      date: 'Creation date:',
    },
    pagination: {
      next: 'Next',
      prev: 'Previous',
    },
    draftPage: {
      removeBtn: 'Remove',
      cancelBtn: 'Cancel',
    },
    kanbanLink: {
      kanban: 'Kanban',
      list: 'Show list',
    },
    newFormRevision: 'Open new standard review',
    newForm: 'New standard form',
  },
  es: {
    tabs: {
      all: 'Todas',
      myAnalysis: 'Mis pendientes',
      myClients: 'Mis clientes',
      draft: 'Bosquejo',
      crm: 'CRM',
    },
    basicOption: {
      yes: 'Sí',
      no: 'No',
    },
    prep: 'a',
    yourAnalysis: 'Sus consultas',
    newAnalysis: 'Nueva consulta',
    analysisFilter: 'Filtro de consultas',
    myAnalysis: 'Mis pendientes',
    myClients: 'Mis clientes',
    allAnalysis: 'Todas las consultas',
    dateFilter: {
      initial: 'Filtrar por periodo',
      custom: 'Personalizar',
      from: 'De',
      to: 'Hasta',
      back: 'Volver',
      apply: 'Aplicar',
      LastMonth: 'Últimos 30 dias',
      LastTwoMonths: 'Últimos 60 dias',
      LastQuarter: 'Últimos 90 dias',
      All: 'Tudo ou histórico',
      Custom: 'Personalizar...',
      orderBy: 'Ordenar por: ',
      desc: 'más nueva',
      asc: 'más antiguo',
      time: 'fecha de caducidad',
    },
    filterModal: {
      clientField: 'Clientes',
      searchFilter: 'Filtro de búsqueda',
      research: 'Búsqueda',
      filterReset: 'Limpiar filtros',
      filterShow: 'Cargar más filtros',
      filterHide: 'Ocultar filtros',
    },
    components: {
      previewWarning: 'El resultado obtenido representa un pre-análisis de su consulta.',
      price: 'Precio disponible',
      legacyId: (legacyId: string) => `(Legado: ${legacyId})`,
      previewCanceled: 'Razón de la cancelación: ',
      noCanceledMessage: 'no informado.',
      previewCanceledRequest: 'Solicitud de cancelacion: ',
      tagsLabel: {
        clientVisible: 'Visible para el cliente',
        clientCreator: 'Creado por el cliente',
      },
      createOrderButton: 'Create order',
    },
    constants: {
      status: {
        key: {
          derogate: 'derogate',
          accepted: 'accepted',
          noStatus: 'non-status',
          notAnalysed: 'non-analysed',
        },
        label: {
          derogate: 'Excepción',
          accepted: 'Aceptado',
          noStatus: 'Sin status',
          notAnalysed: 'No analizado',
        },
      },
      statusAttributes: {
        accepted: 'Aceptado',
        derogate: 'Excepción',
        'non-analysed': 'No analizado',
        'non-status': 'Sin status',
        'pending-analysis': 'Pendiente de análisis',
        error: 'Error',
      },
      internationalStandard: 'Norma Internacional',
      stage: {
        key: {
          processing: 'PROCESSING',
          internalQuery: 'INTERNAL_QUERY',
          underAnalysisEpp: 'UNDER_ANALYSIS_EPP',
          underAnalysisPed: 'UNDER_ANALYSIS_PED',
          underAnalysisRtc: 'UNDER_ANALYSIS_RTC',
          dataValidationRtc: 'DATA_VALIDATION_RTC',
          inValidationEpp: 'IN_VALIDATION_EPP',
          inValidationPed: 'IN_VALIDATION_PED',
          waitingClientReview: 'WAITING_CLIENT_REVIEW',
          pending: 'PENDING',
          underAnalysis: 'UNDER_ANALYSIS',
          inValidation: 'IN_VALIDATION',
          canceled: 'CANCELED',
          rejected: 'REJECTED',
          finalized: 'FINALIZED',
          finalizedWithDerogation: 'FINALIZED_WITH_DEROGATION',
          waitingPrice: 'WAITING_PRICE',
          priceAvailable: 'AVAILABLE_PRICE',
          internalQuerySeller: 'INTERNAL_QUERY_SELLER',
          validationRTC: 'VALIDATION_RTC',
          validationSeller: 'VALIDATION_SELLER',
          clientEdition: 'CLIENT_EDITION',
          underAnalysisGq: 'UNDER_ANALYSIS_GQ',
          inValidationGq: 'IN_VALIDATION_GQ',
          waitingReview: 'WAITING_CLIENT_REVIEW_CLIENT',
          noCustomerResponse: 'NO_CUSTOMER_RESPONSE',
        },
        label: {
          PROCESSING: 'Procesando',
          INTERNAL_QUERY: 'Consulta interna',
          UNDER_ANALYSIS_EPP: 'En revisión PPE',
          UNDER_ANALYSIS_PED: 'En revisión I+D',
          UNDER_ANALYSIS_RTC: 'En revisión RTC',
          DATA_VALIDATION_RTC: 'Corrección de datos (RTC)',
          IN_VALIDATION_EPP: 'En validación (PPE)',
          IN_VALIDATION_PED: 'En validación (I+D)',
          WAITING_CLIENT_REVIEW: 'Esperando a parecer del cliente',
          PENDING: 'Envío pendiente',
          UNDER_ANALYSIS: 'En análisis',
          IN_VALIDATION: 'En validación',
          WAITING_CLIENT_REVIEW_CLIENT: 'Aguardando parecer',
          CANCELED: 'Anulado',
          REJECTED: 'Rechazado',
          FINALIZED: 'Acabado',
          FINALIZED_WITH_DEROGATION: 'Terminó con derogación',
          WAITING_PRICE: 'Cotización en espera',
          AVAILABLE_PRICE: 'Cotización disponible',
          INTERNAL_QUERY_SELLER: 'Consulta del vendedor/ra',
          VALIDATION_RTC: 'Validación RTC',
          VALIDATION_SELLER: 'Validación del vendedor/ra',
          CLIENT_EDITION: 'Edición del cliente',
          UNDER_ANALYSIS_GQ: 'En revisión  GQ',
          IN_VALIDATION_GQ: 'En validación (GQ)',
          NO_CUSTOMER_RESPONSE: 'Sin respuesta del cliente',
        },
      },
    },
    placeholder: {
      noData: 'No hay consultas disponibles para este filtro',
      noDataMyPendings: 'No hay pendencias con los filtros seleccionados',
    },
    defaultMessageGerdau: {
      chatResult: '* Característica automatica estándar Gerdau',
      chatResultShort: '* Estándar de suministro Gerdau.',
      chatResultGerdauAdvice: ' *',
      chatResultNormAdvice: ' (copiado de la norma)',
      tootipChatResult: 'Característica autocompletada',
      analysisPage: '* Estándar de suministro Gerdau',
    },
    tooltips: {
      draft: {
        title: '',
        description: 'Al hacer clic en "Borradores", puede recuperar consultas que no fueron finalizadas en ISA.',
      },
      myPending: {
        title: '',
        description: 'Ya puedes verificar tus consultas pendientes haciendo click en "Mis Pendientes".',
      },
      clientVisible: {
        step1: {
          description: 'Haga clic para seleccionar los elementos que se mostrarán al Cliente.',
          step: 'Paso: 1 of 2',
          btn: 'Próximo',
        },
        step2: {
          description: 'Haga clic para mostrar u ocultar los elementos que seleccionó.',
          step: 'Paso: 2 of 2',
          btn: 'Cerrar',
        },
      },
    },
    headerListSelect: {
      labelQuote: 'Seleccionar elementos para el Cliente',
      labelDraft: 'Seleccionar elementos',
      btnShow: 'Mostrar seleccionados',
      btnHide: 'Ocultar seleccionados',
      btnRemove: 'Eliminar',
      btnCancel: 'Cancelar',
      btnRemoveSelected: 'Remover seleccionados',
    },
    draft: 'Bosquejo',
    timeLimit: {
      tomorrow: 'Su fecha límite: vence mañana',
      today: 'Su fecha límite: vence hoy',
      deadline: 'Su fecha límite: expira',
      expired: 'Su fecha límite: caducada',
      date: 'Fecha de creación:',
    },
    pagination: {
      next: 'Próximo',
      prev: 'Anterior',
    },
    draftPage: {
      removeBtn: 'Eliminar',
      cancelBtn: 'Cancelar',
    },
    kanbanLink: {
      kanban: 'Kanban',
      list: 'Mostrar en la lista',
    },
    newFormRevision: 'Abrir nueva revisión',
    newForm: 'Nueva consulta forma',
  },
};

export const QuoteFilterLabelStrings = {
  pt: {
    id: 'Número Isa',
    steel: 'Aço',
    specification: 'Norma',
    form: 'Forma',
    gaugeMin: 'Bitola mínima',
    gaugeMax: 'Bitola máxima',
    sideMin: 'Lado mínimo',
    sideMax: 'Lado máximo',
    widthMin: 'Largura mínima',
    widthMax: 'Largura máxima',
    thicknessMin: 'Espessura mínima',
    thicknessMax: 'Espessura máxima',
    cornerRadius: 'Raio de canto',
    client: 'Clientes',
    email: 'E-mail',
    status: 'Status',
    plants: 'Plantas',
    flow: 'Tipo da consulta',
    priority: 'Prioridade',
    pindamonhangaba: 'Pindamonhangaba',
    charqueadas: 'Charqueadas',
    mogiDasCruzes: 'Mogi das Cruzes',
    resumoGerdau: 'Gerdau - Resumo',
    hasPrice: 'Preço disponível',
    clientVisible: 'Visível para o cliente',
    provider: 'Criada por',
    user: 'E-mail do criador da Cotação',
  },
  in: {
    id: 'ISA number',
    steel: 'Steel',
    specification: 'Standard',
    form: 'Shape',
    gaugeMin: 'Minimum',
    gaugeMax: 'Maximum',
    sideMin: 'Minimal ',
    sideMax: 'Maximum ',
    widthMin: 'Minimum width',
    widthMax: 'Maximum width',
    thicknessMin: 'Minimum thickness',
    thicknessMax: 'Maximum thickness',
    cornerRadius: 'Corner radius',
    client: 'Clients',
    email: 'E-mail',
    status: 'Status',
    plants: 'Mills',
    flow: 'Quotation type',
    priority: 'Priority',
    pindamonhangaba: 'Pindamonhangaba',
    charqueadas: 'Charqueadas',
    mogiDasCruzes: 'Mogi das Cruzes',
    resumoGerdau: 'Gerdau - Resume',
    hasPrice: 'Available price',
    provider: 'Created by',
    clientVisible: 'Visible to the client',
    user: 'Quote creator email',
  },
  es: {
    id: 'Número de ISA',
    steel: 'Acero',
    specification: 'Estándar',
    form: 'Molde',
    gaugeMin: 'Medida mínima',
    gaugeMax: 'Medida máxima',
    sideMin: 'Lado mínimo',
    sideMax: 'Lado máximo',
    widthMin: 'Ancho mínimo',
    widthMax: 'Ancho máximo',
    thicknessMin: 'Espesor mínimo',
    thicknessMax: 'Espesor máximo',
    cornerRadius: 'Radio de canto',
    client: 'Clientes',
    email: 'Correo electrónico',
    status: 'Status',
    plants: 'Plantas',
    flow: 'Tipo da consulta',
    priority: 'Prioridad',
    pindamonhangaba: 'Pindamonhangaba',
    charqueadas: 'Charqueadas',
    mogiDasCruzes: 'Mogi das Cruzes',
    resumoGerdau: 'Gerdau - Resumen',
    hasPrice: 'Precio disponible',
    clientVisible: 'Visível para el cliente',
    provider: 'Creado por',
    user: 'Correo electrónico del creador de la cotización',
  },
};

export const FormOptions2Dimensions = {
  pt: [
    { key: 'RETANGULAR', label: 'Retangular' },
    { key: 'CHATO', label: 'Chata' },
  ],
  in: [
    { key: 'RETANGULAR', label: 'Rectangular' },
    { key: 'CHATO', label: 'Flat' },
  ],
  es: [
    { key: 'RETANGULAR', label: 'Rectangular' },
    { key: 'CHATO', label: 'Planchuela' },
  ],
};

export const FormOptions1Dimensions = {
  pt: [
    { key: 'REDONDO', label: 'Redonda' },
    { key: 'QUADRADO', label: 'Quadrada' },
    { key: 'SEXTAVADO', label: 'Sextavada' },
  ],
  in: [
    { key: 'REDONDO', label: 'Round' },
    { key: 'QUADRADO', label: 'Square' },
    { key: 'SEXTAVADO', label: 'Hexagonal' },
  ],
  es: [
    { key: 'REDONDO', label: 'Redondo' },
    { key: 'QUADRADO', label: 'Cuadrado' },
    { key: 'SEXTAVADO', label: 'Hexagonal' },
  ],
};

export const FormTypesLabel = {
  pt: {
    None: '',
    Round: 'Redonda',
    Squared: 'Quadrada',
    Hexagon: 'Sextavada',
    Rectangular: 'Retangular',
    Flat: 'Chata',
  },
  in: {
    None: '',
    Round: 'Round',
    Squared: 'Square',
    Hexagon: 'Hexagonal',
    Rectangular: 'Rectangular',
    Flat: 'Flat',
  },
  es: {
    None: '',
    Round: 'Redondo',
    Squared: 'Cuadrado',
    Hexagon: 'Hexagonal',
    Rectangular: 'Rectangular',
    Flat: 'Planchuela',
  },
};

export const FlowTypesLabels = {
  pt: {
    NONE: '',
    NEW_PRODUCT: 'Produto novo',
    GENERAL_SPECIFICATION: 'Revisão norma geral',
    SPECIFIC_SPECIFICATION: 'Revisão norma específica',
    RECURRENT_PRODUCT: 'Produto Recorrente',
    CLIENT_MANUAL: 'Manual do Cliente / Fornecedor',
  },
  in: {
    NONE: '',
    NEW_PRODUCT: 'New product',
    GENERAL_SPECIFICATION: 'General standard review',
    SPECIFIC_SPECIFICATION: 'Specific standard review',
    RECURRENT_PRODUCT: 'Portfolio product',
    CLIENT_MANUAL: 'Customer / Supplier Manual',
  },
  es: {
    NONE: '',
    NEW_PRODUCT: 'Nuevo producto',
    GENERAL_SPECIFICATION: 'Revisión de la norma general',
    SPECIFIC_SPECIFICATION: 'Revisión de norma específica',
    RECURRENT_PRODUCT: 'Producto recurrente',
    CLIENT_MANUAL: 'Manual de Cliente / Proveedor',
  },
};

export const Dimensions = {
  pt: {
    bitola: 'Bitola',
    lado: 'Lado',
    largura: 'Largura',
    espessura: 'Espessura',
  },
  in: {
    bitola: 'Size',
    lado: 'Side',
    largura: 'Width',
    espessura: 'Thickness',
  },
  es: {
    bitola: 'Calibre',
    lado: 'Lado',
    largura: 'Ancho',
    espessura: 'Espesor',
  },
};

export const PriceOptions = {
  pt: [
    { key: null, label: '' },
    { key: true, label: 'Sim' },
    { key: false, label: 'Não' },
  ],
  in: [
    { key: null, label: '' },
    { key: true, label: 'Yes' },
    { key: false, label: 'No' },
  ],
  es: [
    { key: null, label: '' },
    { key: true, label: 'Sí' },
    { key: false, label: 'No' },
  ],
};

export const ClientVisibleOptions = {
  pt: [
    { key: null, label: '' },
    { key: true, label: 'Sim' },
    { key: false, label: 'Não' },
  ],
  in: [
    { key: null, label: '' },
    { key: true, label: 'Yes' },
    { key: false, label: 'No' },
  ],
  es: [
    { key: null, label: '' },
    { key: true, label: 'Sí' },
    { key: false, label: 'No' },
  ],
};

export const ProviderOptions = {
  pt: [
    { key: null, label: '' },
    { key: 'CLIENT', label: 'Cliente' },
    { key: 'GERDAU', label: 'Gerdau' },
  ],
  in: [
    { key: null, label: '' },
    { key: 'CLIENT', label: 'Client' },
    { key: 'GERDAU', label: 'Gerdau' },
  ],
  es: [
    { key: null, label: '' },
    { key: 'CLIENT', label: 'Cliente' },
    { key: 'GERDAU', label: 'Gerdau' },
  ],
};

export const OrderFormStrings = {
  pt: {
    title: 'Solicite um pedido via Cotações',
    steel: 'Aço',
    technicalStandard: 'Norma técnica',
    productType: 'Tipo de produto',
    process: 'Processo',
    form: 'Forma',
    heatTreatment: 'Tratamento térmico',
    finishingDetail: 'Acabamento',
    addButton: 'Adicionar',
    cancellButton: 'Voltar para cotação',
    reviewButton: 'Revisar pedido',
    confirmButton: 'Criar novo pedido',
    notFoundDimension: 'Dimensões não encontradas',
    labels: {
      title: {
        junctionE: 'e',
        diameter: 'Bitola',
        side: 'Lado',
        width: 'Largura',
        thickness: 'Espessura',
        length: 'Comprimento',
      },
      placeHolder: {
        descriptionStart: 'Selecione',
        junctionE: 'e',
        junctionComma: ',',
        descriptionEnd: 'que deseja adicionar',
        diameter: 'a Bitola',
        side: 'o Lado',
        width: 'a Largura',
        thickness: 'Espessura',
        length: 'Comprimento',
      },
      selector: {
        diameter: 'Bitola:',
        side: 'Lado:',
        width: 'Largura:',
        thickness: 'Espessura:',
        length: 'Comprimento:',
        minLength: 'Min:',
        maxLength: 'Max:',
        multiLength: 'Multiplo:',
        multiLengthMin: 'Min:',
        multiLengthMax: 'Max:',
      },
      table: {
        diameter: 'Bitola',
        side: 'Lado',
        width: 'Largura',
        thickness: 'Espessura',
        length: 'Comprimento',
        emptyTable: 'Selecione o item desejado na lista de',
      },
      tableHeader: {
        orderNumber: 'Nº do pedido',
        orderItem: 'Item do pedido (opcional)',
        isaNumber: 'Referência ISA',
        amount: 'Quantidade',
        unity: 'Unidade',
        date: 'Data desejada',
        diameter: 'Bitola',
        side: 'Lado',
        width: 'Largura',
        thickness: 'Espessura',
        length: 'Comprimento',
        multiLenght: 'Múltiplo',
      },
    },
    orderAnalysis: {
      title: 'Pedido enviado para análise!',
      firstRow:
        'Seu pedido foi enviado para análise e será processado pela equipe Gerdau. Neste momento, ele ainda não aparecerá na lista de pedidos implantados.',
      secondRow:
        'Nossa equipe irá analisar o pedido e criar o SKU necessário. Assim que todas as etapas forem concluídas, o pedido estará visível na sua lista de pedidos implantados.',
      thirdRow: 'Agradecemos pela sua confiança.',
      closeButton: 'Fechar',
    },
    warningME: 'O envio de pedidos para Mercado Externo ainda não está disponível.',
    error: 'Erro ao enviar pedido.',
    tooltip: {
      cfCreator: 'Criador da CF:',
      statusResponsible: 'Responsável neste status:',
      client: 'Cliente:',
      status: 'Status:',
      searchType: 'Tipo de consulta:',
      startDate: 'Data e hora de abertura:',
    },
  },
  in: {
    title: 'Solicite um pedido via Cotações',
    steel: 'Aço',
    technicalStandard: 'Norma técnica',
    productType: 'Tipo de produto',
    process: 'Processo',
    form: 'Forma',
    heatTreatment: 'Tratamento térmico',
    finishingDetail: 'Acabamento',
    addButton: 'Adicionar',
    cancellButton: 'Back to quotation',
    reviewButton: 'Revisar pedido',
    confirmButton: 'Criar novo pedido',
    notFoundDimension: 'Dimensões não encontradas',
    labels: {
      title: {
        junctionE: 'e',
        diameter: 'Bitola',
        side: 'Lado',
        width: 'Largura',
        thickness: 'Espessura',
        length: 'Comprimento',
      },
      placeHolder: {
        descriptionStart: 'Selecione',
        junctionE: 'e',
        junctionComma: ',',
        descriptionEnd: 'que deseja adicionar',
        diameter: 'a Bitola',
        side: 'o Lado',
        width: 'a Largura',
        thickness: 'Espessura',
        length: 'Comprimento',
      },
      selector: {
        diameter: 'Bitola:',
        side: 'Lado:',
        width: 'Largura:',
        thickness: 'Espessura:',
        length: 'Comprimento:',
        minLength: 'Min:',
        maxLength: 'Max:',
        multiLength: 'Multiplo:',
        multiLengthMin: 'Min:',
        multiLengthMax: 'Max:',
      },
      table: {
        diameter: 'Bitola',
        side: 'Lado',
        width: 'Largura',
        thickness: 'Espessura',
        length: 'Comprimento',
        emptyTable: 'Selecione o item desejado na lista de',
      },
      tableHeader: {
        orderNumber: 'Nº do pedido',
        orderItem: 'Item do pedido (opcional)',
        isaNumber: 'Referência ISA',
        amount: 'Quantidade',
        unity: 'Unidade',
        date: 'Data desejada',
        diameter: 'Bitola',
        side: 'Lado',
        width: 'Largura',
        thickness: 'Espessura',
        length: 'Comprimento',
        multiLenght: 'Múltiplo',
      },
    },
    orderAnalysis: {
      title: 'Pedido enviado para análise!',
      firstRow:
        'Seu pedido foi enviado para análise e será processado pela equipe Gerdau. Neste momento, ele ainda não aparecerá na lista de pedidos implantados.',
      secondRow:
        'Nossa equipe irá analisar o pedido e criar o SKU necessário. Assim que todas as etapas forem concluídas, o pedido estará visível na sua lista de pedidos implantados.',
      thirdRow: 'Agradecemos pela sua confiança.',
    },
    warningME: 'Order requests to the External Market are not available yet.',
    error: 'Erro ao enviar pedido.',
    tooltip: {
      cfCreator: 'Criador da CF:',
      statusResponsible: 'Responsável neste status:',
      client: 'Cliente:',
      status: 'Status:',
      searchType: 'Tipo de consulta:',
      startDate: 'Data e hora de abertura:',
    },
  },
  es: {
    title: 'Solicite um pedido via Cotações',
    steel: 'Aço',
    technicalStandard: 'Norma técnica',
    productType: 'Tipo de produto',
    process: 'Processo',
    form: 'Forma',
    heatTreatment: 'Tratamento térmico',
    finishingDetail: 'Acabamento',
    addButton: 'Adicionar',
    cancellButton: 'Volver a cotización',
    reviewButton: 'Revisar pedido',
    confirmButton: 'Criar novo pedido',
    notFoundDimension: 'Dimensões não encontradas',
    labels: {
      title: {
        junctionE: 'e',
        diameter: 'Bitola',
        side: 'Lado',
        width: 'Largura',
        thickness: 'Espessura',
        length: 'Comprimento',
      },
      placeHolder: {
        descriptionStart: 'Selecione',
        junctionE: 'e',
        junctionComma: ',',
        descriptionEnd: 'que deseja adicionar',
        diameter: 'a Bitola',
        side: 'o Lado',
        width: 'a Largura',
        thickness: 'Espessura',
        length: 'Comprimento',
      },
      selector: {
        diameter: 'Bitola:',
        side: 'Lado:',
        width: 'Largura:',
        thickness: 'Espessura:',
        length: 'Comprimento:',
        minLength: 'Min:',
        maxLength: 'Max:',
        multiLength: 'Multiplo:',
        multiLengthMin: 'Min:',
        multiLengthMax: 'Max:',
      },
      table: {
        diameter: 'Bitola',
        side: 'Lado',
        width: 'Largura',
        thickness: 'Espessura',
        length: 'Comprimento',
        emptyTable: 'Selecione o item desejado na lista de',
      },
      tableHeader: {
        orderNumber: 'Nº do pedido',
        orderItem: 'Item do pedido (opcional)',
        isaNumber: 'Referência ISA',
        amount: 'Quantidade',
        unity: 'Unidade',
        date: 'Data desejada',
        diameter: 'Bitola',
        side: 'Lado',
        width: 'Largura',
        thickness: 'Espessura',
        length: 'Comprimento',
        multiLenght: 'Múltiplo',
      },
    },
    orderAnalysis: {
      title: 'Pedido enviado para análise!',
      firstRow:
        'Seu pedido foi enviado para análise e será processado pela equipe Gerdau. Neste momento, ele ainda não aparecerá na lista de pedidos implantados.',
      secondRow:
        'Nossa equipe irá analisar o pedido e criar o SKU necessário. Assim que todas as etapas forem concluídas, o pedido estará visível na sua lista de pedidos implantados.',
      thirdRow: 'Agradecemos pela sua confiança.',
    },
    warningME: 'Los pedidos para el Mercado Externo aún no están disponibles.',
    error: 'Erro ao enviar pedido.',
    tooltip: {
      cfCreator: 'Criador da CF:',
      statusResponsible: 'Responsável neste status:',
      client: 'Cliente:',
      status: 'Status:',
      searchType: 'Tipo de consulta:',
      startDate: 'Data e hora de abertura:',
    },
  },
};
