import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Body } from '@atomic/atm.typography';
import { FormContext } from '../form.context';
import { FieldsData } from '../utils/mapPayloadAnalysisOld.dto';
import { IconStep, StepHeader } from './result/form-result.component.style';

export const StepsHeaderItem = props => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const { scrollTo, calcMandatory, handleCreateDraft, handleUpdateDraft, recoveryMode } = React.useContext(FormContext);

  const [mandatoryFieldsLeft, setMandatoryFieldsLeft] = React.useState(0);

  React.useEffect(() => {
    if (props.step && props.step?.questions?.fields) {
      const completedPercent = calcMandatory(Object.values(props.step.questions.fields), props.step.key);
      setMandatoryFieldsLeft(completedPercent);
      const storedDraft = localStorage.getItem('draftQuote');
      let checkIdDraft = null;
      if (storedDraft) {
        checkIdDraft = JSON.parse(storedDraft);
      }

      if (completedPercent === 100 && mandatoryFieldsLeft !== 100) {
        if (!checkIdDraft?.idDraft) {
          props.step.key !== 'CLIENT' && handleCreateDraft();
        } else {
          checkIdDraft.userId === userInfo._id &&
            props.step.key !== 'CLIENT' &&
            !recoveryMode &&
            handleUpdateDraft(checkIdDraft.idDraft);
        }
      }
    }
  }, [props.step, calcMandatory, handleCreateDraft, mandatoryFieldsLeft]);

  const hasAttributeToShow = props.step?.questions?.fields
    ? Object.entries(props.step?.questions?.fields).filter((field: [string, FieldsData]) => field[1].display)
    : [];

  return props.step && JSON.stringify(props.step?.questions?.fields) !== '{}' && hasAttributeToShow.length > 0 ? (
    <StepHeader
      key={props.step.questions.key}
      percent={mandatoryFieldsLeft}
      onClick={() => scrollTo(props.step.questions.key, null, false)}
    >
      {props.step.questions.key !== 'ADDITIONAL' && (
        <IconStep>{mandatoryFieldsLeft === 100 ? <FaIcon.Check /> : <FaIcon.Pen />}</IconStep>
      )}

      <Body>
        {props.step.questions.label}{' '}
        {props.step.questions.key !== 'ADDITIONAL' &&
          `${calcMandatory(props.step.questions?.fields && Object.values(props.step.questions.fields))}%`}
      </Body>
    </StepHeader>
  ) : (
    <></>
  );
};
