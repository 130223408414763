import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { Container } from 'typedi';
import {
  DirectIsaHttpRequestBuilder,
  IsaHttpRequestBuilder,
  //NewFormHttpRequestBuilder,
  NewFormProvHttpRequestBuilder,
} from '@app/data/http';
import { mapDraft } from '../utils/form.dto';
//import { ChatFormResponse } from '@app/models';

export const Draft = {
  async createDraft(params?: any) {
    const defaultHeaders = {
      ...Container.get(IsaHttpRequestBuilder).config.headers,
      language: params.language,
      Authorization: 'Bearer ' + params.authorizationToken,
      'Access-Token': params.accessToken,
    };

    //const url = `${Container.get(NewFormHttpRequestBuilder).config.baseUrl}/forms`;
    const url = `${Container.get(NewFormProvHttpRequestBuilder).config.baseUrl}/forms`;

    try {
      const { data, status } = await axios.post(url, params.query, { headers: defaultHeaders });

      if (status === 201) {
        localStorage.setItem(
          'draftQuote',
          JSON.stringify({
            idDraft: data.data.formId,
            userId: params.userId,
            flow: params.query.flow,
          }),
        );
        return data.data;
      } else {
        return [];
      }
    } catch (error) {
      //handleUnauthorized(error);
      return [];
    }
  },
  async updateDraft(params?: any) {
    const defaultHeaders = {
      ...Container.get(IsaHttpRequestBuilder).config.headers,
      language: params.language,
      Authorization: 'Bearer ' + params.authorizationToken,
      'Access-Token': params.accessToken,
    };

    //const url = `${Container.get(NewFormHttpRequestBuilder).config.baseUrl}/forms`;
    const url = `${Container.get(NewFormProvHttpRequestBuilder).config.baseUrl}/forms/${params.idDraft}`;

    try {
      const { data, status } = await axios.put(url, params.query, { headers: defaultHeaders });

      if (status === 200) {
        return data.data;
      } else {
        return [];
      }
    } catch (error) {
      return [];
    }
  },

  async getDraft(params?: any) {
    const defaultHeaders = {
      ...Container.get(IsaHttpRequestBuilder).config.headers,
      language: params.language,
      Authorization: 'Bearer ' + params.authorizationToken,
      'Access-Token': params.accessToken,
    };

    const url = `${Container.get(NewFormProvHttpRequestBuilder).config.baseUrl}/forms/${params.idDraft}`;

    try {
      const { data, status } = await axios.get(url, { headers: defaultHeaders });
      if (status === 200) {
        return mapDraft(data.data);
      } else {
        return [];
      }
    } catch (error) {
      //handleUnauthorized(error);
      return [];
    }
  },

  async getFormsAnalysis(params?: any) {
    const defaultHeaders = {
      ...Container.get(DirectIsaHttpRequestBuilder).config.headers,
      language: params.language,
      Authorization: 'Bearer ' + params.authorizationToken,
      'Access-Token': params.accessToken,
    };

    // const url = `${Container.get(DirectIsaHttpRequestBuilder).config.baseUrl}/analyses/forms/${params.analysisId}/${
    //   params.action
    // }`;

    const url = `${Container.get('CrmUrl')}/v1/analyses/forms/${params.analysisId}/${params.action}`;

    try {
      const { data, status } = await axios.get(url, { headers: defaultHeaders });
      if (status === 200) {
        return data.formId;
      } else {
        return [];
      }
    } catch (error) {
      //handleUnauthorized(error);
      return [];
    }
  },
};

export function useCreateDraft(onSuccess?, onError?, onSettled?) {
  return useMutation(Draft.createDraft, {
    onSuccess,
    onError,
    onSettled,
    retry: false,
  });
}

export function useUpdateDraft(onSuccess?, onError?, onSettled?) {
  return useMutation(Draft.updateDraft, {
    onSuccess,
    onError,
    onSettled,
    retry: false,
  });
}

export function useGetDraft(onSuccess?, onError?, onSettled?) {
  return useMutation(Draft.getDraft, {
    onSuccess,
    onError,
    onSettled,
    retry: false,
  });
}

export function useGetFormsAnalysis(params?: any, onSuccess?) {
  return useQuery(['getFormsAnalysis'], () => Draft.getFormsAnalysis(params), {
    onSuccess,
    enabled: params.enabled,
    refetchOnWindowFocus: false,
    retry: false,
  });
}
