const RoleDisplayName = {
  pt: {
    Epp: 'EPP',
    Ped: 'P&D',
    Rtc: 'RTC',
    Gq: 'GQ',
    Admin: 'Admin',
    Seller: 'Vendedor',
    Client: 'Cliente',
    Scm: 'Supply Chain',
    Squad: 'Squad',
  },
  in: {
    Epp: 'EPP',
    Ped: 'P&D',
    Rtc: 'RTC',
    Gq: 'GQ',
    Admin: 'Admin',
    Seller: 'Seller',
    Client: 'Client',
    Scm: 'Supply Chain',
    Squad: 'Squad',
  },
  es: {
    Epp: 'EPP',
    Ped: 'P&D',
    Rtc: 'RTC',
    Gq: 'GQ',
    Admin: 'Admin',
    Seller: 'Vendedor',
    Client: 'Cliente',
    Scm: 'Supply Chain',
    Squad: 'Squad',
  },
};

export const TechnicalAnalysisStrings = {
  pt: {
    priority: 'Prioridade:',
    complexity: 'Complexidade:',
    price: 'Preço',
    creatorName: 'Criador da CF:',
    gerdauStatus: 'Status interno:',
    client: 'Cliente:',
    clientStatus: 'Status externo:',
    isa: 'ISA:',
    flow: 'Tipo de consulta:',
    email: 'E-mail do responsável:',
    date: 'Data e hora de abertura: ',
    currentResponsibles: 'Responsável neste status:',
    editResponsiblesError: 'Você não tem permissão para realizar esta ação.',
    legacyId: (legacyId: string) => `(Legado: ${legacyId})`,
    header: {
      title: 'Análise técnica',
      newProductEpp: 'Novo Produto EPP',
      tooltip: {
        newAnalysis: 'Nova consulta',
        norm: 'Norma',
        assets: 'Anexos',
        refreshAnalysis: 'Recarregar análise',
        similar: 'Ver similares',
        download: 'Baixar cotação',
        cancelQuotation: 'Cancelar cotação',
        content: {
          title: 'Novo!',
          description: 'Agora você pode ver os materiais similares',
        },
      },
      createOrderButton: 'Criar pedido',
    },
    footer: {
      price: (price: string) => `Preço: ${price}/ton`,
      priceValidity: (expireDate: string) =>
        `Esse preço é válido até ${expireDate}. Qualquer especificação altera o valor do produto`,
      observation: (value: string) => `Observação: ${value}`,
      downloadQuotation: 'Baixar cotação',
    },
    tab: {
      newAttribute: 'Nova característica',
      validatedPlant: 'Essa planta foi validada',
      selectedPlant: 'Essa planta foi selecionada',
      requestedPlant: 'Essa planta foi requisitada para análise',
      plantSummary: 'Resumo',
      previewMessage: '* Essa planta foi escolhida para ser analisada',
      processingMessage:
        'Consulta em processamento - Quando o processo for concluído, as ações estarão liberadas para interação.',
      messageResume: (plant: string) => `* A Usina ${plant} foi selecionada para exibição ao cliente`,
      resumeTitle: (plant: string) => `Resumo da análise técnica de ${plant}`,
      internalComment: 'Comentários Gerdau',
      externalComment: 'Comentários Gerdau/Cliente',
      previewComments: (length: number) => `Comentários (${length})`,
      attributeOrder: {
        success: 'Alterado com sucesso',
        error: 'Erro ao alterar a ordem',
      },
    },
    assets: {
      assetCell: {
        sentBy: (email: string, date: string) => `Enviado por ${email} em ${date}`,
        clientVisible: 'Visível para o cliente',
      },
      assetModal: {
        title: 'Anexos',
        noData: 'Sem anexos',
        selectAll: 'Selecionar todos',
        downloadAll: (assetsAmount: number) => `Baixar (${assetsAmount})`,
        close: 'Fechar',
        label: 'Anexe aqui os documentos necessários:',
      },
    },
    attributes: {
      clientComments: 'Comentários Gerdau/Cliente',
      attrComments: 'Comentários sobre esta característica',
      yes: 'Sim',
      no: 'Não',
      derogateStatus: {
        accepted: 'Aceita',
        rejected: 'Rejeitada',
      },
      derogateListTitle: 'Avaliações pendentes:',
      rangeAttribute: {
        min: (value?: number, unit?: string) => !isNaN(value) && `Min: ${value} ${unit ? unit : ''}`,
        max: (value?: number, unit?: string) => !isNaN(value) && `Max: ${value} ${unit ? unit : ''}`,
      },
      attributeCell: {
        tagAnalysis: 'Análise',
        tagPending: 'Avaliação pendente',
        btnAnalysis: 'Analisar',
        additionalInformation: 'Informações complementares',
        gerdauComments: (commentsLength: number) =>
          `Comentários Gerdau${commentsLength > 0 ? ` (${commentsLength})` : ''}`,
        cancel: 'Cancelar',
        save: 'Salvar',
        derogationTitle: 'Proposta de Derroga',
        derogationReason: (reason?: string) => (reason ? `Motivo:||${reason}` : 'Motivo não informado'),
        derogationSuggestion: (title: string, suggestion?: string) =>
          suggestion ? `Proposta para ${title.toLowerCase()}:||${suggestion}` : 'Proposta não informada',
        reject: 'Rejeitar',
        accept: 'Aceitar',
        rejected: 'Rejeitada',
        accepted: 'Aceita',
        image: {
          removeImage: 'Excluir imagem',
          addImage: 'Adicionar mais imagens',
          uploadClickButton: 'Clique aqui ou arraste o arquivo (somente imagens: exemplos jpg, png, tif, etc)',
          alertImage: 'Você precisa editar para excluir essa imagem.',
        },
        form: {
          reason: 'Motivo',
          generalTitle: (title: string) => `Proposta para ${title.toLowerCase()}`,
          minTitle: (unit?: string) => `Mínimo ${unit ? `(${unit})` : ''}`,
          maxTitle: (unit?: string) => `Máximo ${unit ? `(${unit})` : ''}`,
          numberTitle: (title: string, unit: string) =>
            `Proposta para ${title.toLowerCase()} ${unit ? `(${unit})` : ''}`,
          validator: {
            range: 'Derroga deve conter "Mínimo" ou "Máximo"',
            alertMandatory: 'Este campo é obrigatório',
          },
        },
        errorMessages: {
          reasonRequired: 'Todas as linhas marcadas com derroga devem conter o motivo',
          invalidRange: 'O valor mínimo não pode ser maior ou igual ao valor máximo',
          genericError: 'Algo deu errado, tente novamente mais tarde',
        },
        successMessage: (attributeName: string) => `A característica "${attributeName}" foi alterada com sucesso`,
        successDeleteMessage: (attributeName: string) => `A característica "${attributeName}" foi deletada com sucesso`,
        errorMessage: 'Algo deu errado ao alterar',
        criticalLabel: 'Característica crítica',
        descriptiveAnalysis: {
          title: 'Análise descritiva',
          element: 'Elemento',
          amplitude: 'Amplitude',
          residualMin: 'Residual min.',
          residualMax: 'Residual máx.',
          limit: 'Limite',
          accepted: 'Aceito',
          derogate: 'Derroga',
          suggestion: 'Sugestão:',
          diameter: 'Bitola',
          lengthMax: 'Comprimento máx.',
          lengthMin: 'Comprimento min.',
          other: 'Outro',
          side: 'Lado',
          steelProcess: 'Tratamento térmico',
          thickness: 'Espessura',
          width: 'Largura',
        },
      },
      newAttribute: {
        title: 'Adição de nova característica',
        label: {
          attributeName: 'Nome da característica',
          attributeValue: 'Valor da característica',
        },
        add: 'Adicionar',
        save: 'Salvar',
        replicateAttribute: 'Replicar característica para todas as plantas',
        alertSpecialChars: 'Não utilize caracteres especiais como % ! * ( ) ; : @ & = + $ , / ? # [ ] < >',
        alertMandatory: 'Este campo é obrigatório',
        messages: {
          success: 'Característica adicionada com sucesso',
          error: 'Houve um problema ao adicionar/editar a característica, tente novamente mais tarde',
          editSuccess: 'Característica editada com sucesso',
        },
      },
      editAttribute: {
        title: 'Editar Característica',
        label: 'Editar',
        mandatoryField: 'Campo obrigatório',
        otherField: 'Informe o novo valor',
        save: 'Salvar',
        back: 'Voltar para lista',
        blankOption: 'Selecione',
        otherOption: 'OUTRO',
        editSuccess: 'Característica editada com sucesso',
      },
      complementaryModal: {
        title: (attributeTitle: string) => `Informações complementares de ${attributeTitle}`,
      },
      tableAttribute: {
        reason: 'Motivo',
        suggestion: 'Sugestão',
        min: 'Min',
        max: 'Max',
      },
      testMethod: {
        title: 'Tipo de ensaio desta característica',
        options: {
          other: 'Outro',
          noTestMethod: 'Não aprensetar tipo de ensaio',
        },
        errorMessages: {
          genericError: 'Algo deu errado, tente novamente mais tarde',
        },
        alertMandatory: 'Este campo é obrigatório',
        label: 'insira uma descrição',
        submitButton: 'Salvar ensaio',
        cancelButton: 'Cancelar',
      },
      jominyAdvice:
        'Atenção: Se possível (se há fórmula aplicável, Ex. SEP 1664), propor para que jominy seja teórico no campo de derroga.',
    },
    tooltipApqp: {
      description: 'Anexe o arquivo apqp.xls aqui quando preenchido.',
      buttonText: 'Entendi',
    },
    tooltipSendToGerdau: {
      title: '',
      description: 'Quase lá! Clique no botão "Enviar para Gerdau" e conclua sua solicitação de cotação.',
      buttonText: 'Fechar',
    },
    toolTipRequestAnalysis: {
      description: 'Valide ao menos uma planta para prosseguir com a Consulta de Fabricação.',
      buttonText: 'Fechar',
      steps: '1 de 2',
    },
    toolTipStatusButton: {
      description: 'Para prosseguir, clique no botão e selecione a opção desejada.',
      buttonText: 'Fechar',
      steps: '2 de 2',
    },
    toolTipPendingButton: {
      description: 'Você ainda tem avaliação de características pendentes.',
    },
    comment: {
      noData: 'Sem comentários para exibição',
      replicateComment: 'Replicar comentário para todas as plantas',
      placeholder: 'Escreva aqui seu comentário...',
      gerdauOnly: 'Visível apenas para Gerdau',
      errors: {
        post: 'Algo deu errado ao postar este comentário, por favor tente novamente mais tarde.',
        get: 'Algo deu errado ao recuperar os comentários, por favor tente novamente mais tarde.',
        edit: 'Algo deu errado ao editar este comentário, por favor tente novamente em alguns minutos.',
        delete: 'Algo deu errado ao deletar este comentário, por favor tente novamente mais tarde.',
      },
      success: {
        post: 'Comentário enviado com sucesso.',
        edit: 'Comentário editado com sucesso.',
        delete: 'Comentário deletado com sucesso.',
      },
      tabs: {
        internal: 'Para a equipe Gerdau',
        external: 'Para o cliente',
      },
    },
    status: {
      sendToGerdau: 'Enviar para Gerdau',
      seeHistory: 'Históricos da consulta',
      updatedStatusTo: 'atualizou o status para',
      historyModalTitle: 'Histórico da consulta',
      pendingButton: 'Avaliação pendente',
      statusModal: {
        changeStatus: (label: string) => `Mudar status para ${label}`,
        placeholder: 'Escreva seu comentário...',
        send: 'Enviar',
        emailFields: {
          sendTo: 'Enviar para',
          sendToProfile: (profile: string) => `Enviar para ${RoleDisplayName.pt[profile]}`,
          emailPlaceholder: 'E-mail',
          emailMultiPlaceholder: 'Separe por "," para enviar à mais de um e-mail',
          validator: {
            email: 'E-mail inválido',
          },
        },
        skuField: {
          label: 'Informe o SKU criado nesta Consulta',
          labelOptional: 'Este campo não é obrigatório',
          placeholder: 'Separe por "," para enviar mais de um SKU',
          alertMandatory: 'Este campo é obrigatório',
          alertSkuNumber: 'Utilize apenas números',
          alertSkuFormat: 'O código precisa ter 9 dígitos',
        },
        skuSimilar: {
          label: 'SKU Similar para cotação',
          labelOptional: 'Este campo não é obrigatório',
          placeholder: 'Utilize apenas números',
          alertMandatory: 'Este campo é obrigatório',
          alertSkuNumber: 'Utilize apenas números',
          alertSkuFormat: 'O código precisa ter 9 dígitos',
        },
        skuSimilarPrice: {
          label: 'Custo padrão do SKU Similar - (R$)',
          labelOptional: 'Este campo não é obrigatório',
          alertMandatory: 'Este campo é obrigatório',
          alertSkuNumber: 'Utilize apenas números',
          alertSkuFormat: 'O código precisa ter 9 dígitos',
        },
        skuObservation: {
          label: 'Observações referente ao SKU Similar',
          labelOptional: 'Este campo não é obrigatório',
          placeholder: 'Utilize apenas números',
          alertMandatory: 'Este campo é obrigatório',
          alertSkuNumber: 'Utilize apenas números',
          alertSkuFormat: 'O código precisa ter 9 dígitos',
        },
        observationField: {
          labelGerdau: 'Observação para a equipe Gerdau',
          labelClient: 'Observação para o cliente',
        },
      },
      peddingModal: {
        title: 'Enviar para Gerdau',
        placeholder: 'Escreva seu comentário...',
        send: 'Enviar para Gerdau',
        partNumberQuestion: 'Esta cotação já possui Part Number associados (PNs)?',
        options: {
          yes: 'Sim',
          no: 'Não',
        },
        partNumberField: {
          label: 'Informe o Part Number',
          alertMandatory: 'Este campo é obrigatório',
        },
        observationField: {
          placeholder: 'Coloque outras observações que julgar necessário. (opcional)',
          alertMandatory: 'Este campo é obrigatório',
        },
      },
      cancelModal: {
        title: 'Cancelar cotação',
        text: 'Deseja realmente cancelar esta cotação?',
        obervationLabel: 'Motivo',
        alertMandatory: 'Este campo é obrigatório',
        btnSubmit: 'Sim, cancelar cotação',
        btnCancel: 'Não',
        btnHeader: 'Cancelar',
      },
    },
    components: {
      downloadPdf: {
        title: 'Selecione as plantas que serão colocadas no PDF',
        generatePdf: 'Gerar PDF',
        cancel: 'Cancelar',
        errors: {
          selectAtLeastOne: 'Escolha pelo menos uma planta para gerar o PDF',
        },
      },
      about: {
        optionsButton: 'Opções',
        analysis: 'Consulta',
        editAnalysis: 'Editar',
        duplicateAnalysis: 'Duplicar',
        share: 'Compartilhar',
        generatePdf: 'Gerar PDF',
        generateTitlePdf: 'Gerar PDF da consulta',
        sendToBagg: 'Enviar ao BAGG',
      },
      messages: {
        success: 'Status alterado com sucesso',
        sendToBaggSuccess: 'Cotação reenviada ao Bagg com sucesso',
        alert: 'Algo deu errado:',
        forbidden: 'Você não tem permissão para acessar esta cotação.',
      },
      attachments: {
        no: 'Sim, ainda não anexei',
        yes: 'Sim, já está nos anexos',
      },
      observationStatusLabel: 'Observações do Cliente',
      buttonMES: 'Faça a análise da CF utilizando os dados do MES',
    },
    modal: {
      similarMaterials: {
        title: 'Materiais similares',
        legend: '** Essa característica difere do que foi solicitado na Consulta.',
        noData: 'Não foram encontrados itens similares para este caso.',
        cancel: 'Cancelar',
        filename: 'Materiais similares',
        loading: 'Carregando...',
      },
    },
    unfinishedAdvice: {
      text: 'Você tem 1 cotação em progresso, deseja recuperar?',
      btnLabel: 'Recuperar consulta',
    },
    error: 'Houve um erro ao realizar a ação, por favor tente novamente mais tarde',
    attributeDerogateBadges: {
      accepted: { label: 'Aceita', color: 'success' },
      rejected: { label: 'Rejeitada', color: 'warning' },
    },
    apqpPeOption: {
      notAttach: 'Sim, ainda não anexei',
      yes: 'Sim',
    },
    responsibles: {
      add: 'Adicionar outro responsável',
      remove: 'Remover responsável',
    },
    branding: {
      edit: 'Editar marca',
      title: 'Linha de Produto',
      error: 'Erro ao salvar marca.',
      success: 'Marca alterada com sucesso.',
      tooltip: 'Você precisa atribuir uma linha de produto para trocar de status.',
    },
    skuModalstatus: {
      btnAdd: 'Adicionar SKU',
      title: 'SKUs',
      btnSend: 'Enviar',
      skuField: {
        label: 'Informe o SKU criado nesta Consulta',
        placeholder: 'Separe por "," para enviar mais de um SKU',
        alertMandatory: 'Este campo é obrigatório',
        alertSkuNumber: 'Utilize apenas números',
        alertSkuFormat: 'O código precisa ter 9 dígitos',
      },
    },
  },
  in: {
    priority: 'Priority:',
    complexity: 'Complexity:',
    price: 'Price',
    creatorName: 'Creator of CF:',
    gerdauStatus: 'Internal status:',
    client: 'Customer:',
    clientStatus: 'External status:',
    isa: 'ISA:',
    flow: 'Quotation type:',
    email: 'Responsible person email:',
    date: 'Opening date and time: ',
    currentResponsibles: 'Responsible in this status:',
    editResponsiblesError: 'You are not allowed to perform this action.',
    legacyId: (legacyId: string) => `(Legacy: ${legacyId})`,
    header: {
      title: 'Technical analysis',
      newProductEpp: 'New Product EPP',
      tooltip: {
        newAnalysis: 'New quotation',
        norm: 'Norm',
        assets: 'Attachments',
        refreshAnalysis: 'Reload analysis',
        similar: 'Similar materials',
        download: 'Download quotation',
        cancelQuotation: 'Cancel quotation',
        content: {
          title: 'New!',
          description: 'Now you can see similar materials',
        },
      },
      createOrderButton: 'Create order',
    },
    footer: {
      price: (price: string) => `Price: ${price}/ton`,
      priceValidity: (expireDate: string) =>
        `This price is valid until  ${expireDate}. Any specification changes the value of the product`,
      observation: (value: string) => `Observation: ${value}`,
      downloadQuotation: 'Download quotation',
    },
    tab: {
      newAttribute: 'New characteristic',
      validatedPlant: 'This Mill has been validated',
      selectedPlant: 'This Mill has been selected',
      requestedPlant: 'This Mill was requested for analysis',
      plantSummary: 'Summary',
      previewMessage: '* This Mill was chosen to be analyzed',
      processingMessage:
        'Quotation in process - When the process is complete, the actions will be released for interaction.',
      messageResume: (plant: string) => `* The mill ${plant} has been selected for customer display`,
      resumeTitle: (plant: string) => `Summary of technical analysis of ${plant}`,
      internalComment: 'Gerdau Comments',
      externalComment: 'Gerdau/Customer Comments',
      previewComments: (length: number) => `Comments (${length})`,
      attributeOrder: {
        success: 'Successfully changed',
        error: 'Error changing order',
      },
    },
    assets: {
      assetCell: {
        sentBy: (email: string, date: string) => `Posted by ${email} on ${date}`,
        clientVisible: 'Visible to the customer',
      },
      assetModal: {
        title: 'Attachments',
        noData: 'No attachments',
        selectAll: 'Select all',
        downloadAll: (assetsAmount: number) => `Download (${assetsAmount})`,
        close: 'Close',
        label: 'Attach the necessary documents here:',
      },
    },
    attributes: {
      clientComments: 'Gerdau/Customer Comments',
      attrComments: 'Comments about this characteristic',
      yes: 'Yes',
      no: 'No',
      derogateStatus: {
        accepted: 'Accepted',
        rejected: 'Rejected',
      },
      derogateListTitle: 'Pending evaluations:',
      rangeAttribute: {
        min: (value?: number, unit?: string) => !isNaN(value) && `Min: ${value} ${unit ? unit : ''}`,
        max: (value?: number, unit?: string) => !isNaN(value) && `Max: ${value} ${unit ? unit : ''}`,
      },

      attributeCell: {
        tagAnalysis: 'Analysis',
        tagPending: 'Pending evaluation',
        btnAnalysis: 'Analyze',
        additionalInformation: 'Additional information',
        gerdauComments: (commentsLength: number) =>
          `Gerdau comments${commentsLength > 0 ? ` (${commentsLength})` : ''}`,
        cancel: 'Cancel',
        save: 'Save',
        derogationTitle: 'Exception Proposal',
        derogationReason: (reason?: string) => (reason ? `Reason:||${reason}` : 'Reason not informed'),
        derogationSuggestion: (title: string, suggestion?: string) =>
          suggestion ? `Proposal for ${title.toLowerCase()}:||${suggestion}` : 'Proposal not informed',
        reject: 'Reject',
        accept: 'Accept',
        accepted: 'Accepted',
        rejected: 'Rejected',
        image: {
          removeImage: 'Delete image',
          addImage: 'Add more images',
          uploadClickButton: 'Click here or drag the file (images only: examples jpg, png, tif, etc)',
          alertImage: 'You need to edit to delete this image.',
        },
        form: {
          reason: 'Reason',
          generalTitle: (title: string) => `Proposal for ${title.toLowerCase()}`,
          minTitle: (unit?: string) => `Minimum ${unit ? `(${unit})` : ''}`,
          maxTitle: (unit?: string) => `Maximum ${unit ? `(${unit})` : ''}`,
          numberTitle: (title: string, unit: string) =>
            `Proposal for  ${title.toLowerCase()} ${unit ? `(${unit})` : ''}`,
          validator: {
            range: 'Proposal must contain "Minimum" or "Maximum"',
            alertMandatory: 'This field is mandatory',
          },
        },
        errorMessages: {
          reasonRequired: 'All lines marked with proposal must contain the reason',
          invalidRange: 'The minimum value cannot be greater than the maximum value.',
          genericError: 'Something went wrong, try again later',
        },
        successMessage: (attributeName: string) => `The characteristic "${attributeName}" was changed successfully`,
        errorMessage: 'Something went wrong when changed',
        criticalLabel: 'Critical characteristic',
        descriptiveAnalysis: {
          title: 'Descriptive analysis',
          element: 'Element',
          amplitude: 'Amplitude',
          residualMin: 'Residual min.',
          residualMax: 'Residual max.',
          limit: 'Limit',
          accepted: 'Accepted',
          derogate: 'Derogate',
          suggestion: 'Suggestion:',
          diameter: 'Diameter',
          lengthMax: 'Length max.',
          lengthMin: 'Length min.',
          other: 'Other',
          side: 'Side',
          steelProcess: 'TSteel process',
          thickness: 'Thickness',
          width: 'Width',
        },
      },
      newAttribute: {
        title: 'Addition new characteristic',
        label: {
          attributeName: 'Characteristic name',
          attributeValue: 'Characteristic value',
        },
        add: 'Add',
        save: 'Save',
        replicateAttribute: 'Replicate characteristic for all Mills',
        alertSpecialChars: 'Do not use special characters  % ! * ( ) ; : @ & = + $ , / ? # [ ] < >',
        alertMandatory: 'This field is mandatory',
        messages: {
          success: 'Characteristic added successfully',
          error: 'There was a problem adding/editing the characteristic, please try again later',
          editSuccess: 'Characteristic edited successfully',
        },
      },
      editAttribute: {
        title: 'Edit Characteristic',
        label: 'Edit',
        mandatoryField: 'Mandatory field',
        otherField: 'Enter the new value',
        save: 'Save',
        back: 'Back to list',
        blankOption: 'Select',
        otherOption: 'OTHER',
        editSuccess: 'Characteristic edited successfully',
      },
      complementaryModal: {
        title: (attributeTitle: string) => `Complementary information of ${attributeTitle}`,
      },
      tableAttribute: {
        reason: 'Reason',
        suggestion: 'Suggestion',
        min: 'Min',
        max: 'Max',
      },
      testMethod: {
        title: 'Test type of this characteristic',
        options: {
          other: 'Other',
          noTestMethod: 'Do not submit test',
        },
        errorMessages: {
          genericError: 'Something went wrong, try again later',
        },
        alertMandatory: 'This field is mandatory',
        label: 'enter a description',
        submitButton: 'Save test',
        cancelButton: 'Cancel',
      },
      jominyAdvice:
        'Atenção: Se possível (se há fórmula aplicável, Ex. SEP 1664), propor para que jominy seja teórico no campo de derroga.',
    },
    tooltipApqp: {
      description: 'Attach the apqp.xls file here when completed.',
      buttonText: 'Ok, understood',
    },
    tooltipSendToGerdau: {
      title: '',
      description: 'Almost there! Click on the "Send to Gerdau" button and complete your quotation.',
      buttonText: 'Close',
    },
    toolTipRequestAnalysis: {
      description: 'Validate at least one mill to proceed with the Manufacturing Quotation.',
      buttonText: 'Close',
      steps: '1 of 2',
    },
    toolTipStatusButton: {
      description: 'To proceed, click on the button and select the desired option.',
      buttonText: 'Close',
      steps: '2 of 2',
    },
    toolTipPendingButton: {
      description: 'You still have characteristic evaluation pending.',
    },
    comment: {
      noData: 'No comments for display',
      replicateComment: 'Replicate comment for all Mills',
      placeholder: 'Write your comment here...',
      gerdauOnly: 'Only visible to Gerdau',
      errors: {
        post: 'Something went wrong while posting this comment, please try again later.',
        get: 'Something went wrong while retrieving comments, please try again later.',
        edit: 'Something went wrong while editing this comment, please try again later.',
        delete: 'Something went wrong deleting this comment, please try again later.',
      },
      success: {
        post: 'Comment sent successfully.',
        edit: 'Comment successfully edited.',
        delete: 'Comment successfully deleted.',
      },
      tabs: {
        internal: 'For the Gerdau team',
        external: 'For the client',
      },
    },
    status: {
      sendToGerdau: 'Send to Gerdau',
      seeHistory: 'Quotation history',
      updatedStatusTo: 'updated status to',
      historyModalTitle: 'Quotation history',
      pendingButton: 'Pending evaluation',
      statusModal: {
        changeStatus: (label: string) => `Change status to ${label}`,
        placeholder: 'Write your comment...',
        send: 'Send',
        emailFields: {
          sendTo: 'Send to',
          sendToProfile: (profile: string) => `Send to ${RoleDisplayName.in[profile]}`,
          emailPlaceholder: 'E-mail',
          emailMultiPlaceholder: 'Separate by "," to send to more than one email',
          validator: {
            email: 'Invalid email',
          },
        },
        skuField: {
          label: 'Inform the SKU created in this Quotation',
          labelOptional: 'This field is not mandatory',
          placeholder: 'Separate by "," to send more than one SKU',
          alertMandatory: 'This field is mandatory',
          alertSkuNumber: 'Use only numbers',
          alertSkuFormat: 'The code must be 9 digits',
        },
        skuSimilar: {
          label: 'SKU Similar for quote',
          labelOptional: 'This field is not mandatory',
          placeholder: 'Use only numbers',
          alertMandatory: 'This field is mandatory',
          alertSkuNumber: 'Use only numbers',
          alertSkuFormat: 'The code must be 9 digits',
        },
        skuSimilarPrice: {
          label: 'Standard cost of Similar SKU - (R$)',
          labelOptional: 'This field is not mandatory',
          alertMandatory: 'This field is mandatory',
          alertSkuNumber: 'Use only numbers',
          alertSkuFormat: 'The code must be 9 digits',
        },
        skuObservation: {
          label: 'Notes regarding Similar SKU',
          labelOptional: 'This field is mandatory',
          placeholder: 'The code must be 9 digits',
          alertMandatory: 'This field is mandatory',
          alertSkuNumber: 'Use only numbers',
          alertSkuFormat: 'The code must be 9 digits',
        },
        observationField: {
          labelGerdau: 'Observation to the Gerdau team',
          labelClient: 'Observation to the client',
        },
      },
      peddingModal: {
        title: 'Send to Gerdau',
        send: 'Send to Gerdau',
        partNumberQuestion: 'Does this quote already have associated Part Numbers (PNs)?',
        options: {
          yes: 'Yes',
          no: 'No',
        },
        partNumberField: {
          label: 'Inform the Part Number',
          alertMandatory: 'This field is mandatory',
        },
        observationField: {
          placeholder: 'Add other notes if necessary. (optional)',
          alertMandatory: 'This field is mandatory',
        },
      },
      cancelModal: {
        title: 'Cancel quote',
        text: 'Do you really want to cancel this quote?',
        obervationLabel: 'Reason',
        alertMandatory: 'This field is mandatory',
        btnSubmit: 'Yes, cancel quote',
        btnCancel: 'No',
        btnHeader: 'Cancel',
      },
    },
    components: {
      downloadPdf: {
        title: 'Select the mill that will be placed in the PDF',
        generatePdf: 'Generate PDF',
        cancel: 'Cancel',
        errors: {
          selectAtLeastOne: 'Choose at least one mill to generate the PDF',
        },
      },
      about: {
        optionsButton: 'Options',
        analysis: 'Quotation',
        editAnalysis: 'Edit',
        duplicateAnalysis: 'Duplicate',
        share: 'Share',
        generatePdf: 'Generate PDF',
        generateTitlePdf: 'Generate quotation PDF',
        sendToBagg: 'Send to BAGG',
      },
      messages: {
        success: 'Status changed successfully',
        alert: 'Something went wrong:',
        sendToBaggSuccess: 'Quotation resent to Bagg successfully',
        forbidden: 'You dont have permission to access this quote.',
      },
      attachments: {
        no: 'Yes, I havent attached it yet',
        yes: 'Yes, its already in the attachments',
      },
      observationStatusLabel: 'Customer Notes',
      buttonMES: 'Perform CF analysis using MES data',
    },
    modal: {
      similarMaterials: {
        title: 'Similar materials',
        legend: '** This characteristic differs from the one requested.',
        noData: 'No similar items found for this case.',
        cancel: 'Cancel',
        filename: 'Similar materials',
        loading: 'Loading...',
      },
    },
    unfinishedAdvice: {
      text: 'You have 1 quotation in progress, do you want to retrieve it?',
      btnLabel: 'Retrieve quotation',
    },
    error: 'There was an error performing the action, please try again later',
    attributeDerogateBadges: {
      accepted: { label: 'Accepted', color: 'success' },
      rejected: { label: 'Rejected', color: 'warning' },
    },
    apqpPeOption: {
      notAttach: 'Yes, I havent attached it yet.',
      yes: 'Yes',
    },
    responsibles: {
      add: 'Add another responsible',
      remove: 'Remove responsible',
    },
    branding: {
      edit: 'Edit brand',
      title: 'Product line',
      error: 'Error saving brand.',
      success: 'Brand changed successfully.',
      tooltip: 'You need to assign a product line to change status.',
    },
    skuModalstatus: {
      btnAdd: 'Add SKU',
      title: 'SKUs',
      btnSend: 'Send',
      skuField: {
        label: 'Inform the SKU created in this Quotation',
        placeholder: 'Separate by "," to send more than one SKU',
        alertMandatory: 'This field is mandatory',
        alertSkuNumber: 'Use only numbers',
        alertSkuFormat: 'The code must be 9 digits',
      },
    },
  },
  es: {
    priority: 'Prioridad:',
    complexity: 'Complejidad:',
    price: 'Precio',
    creatorName: 'Creador de CF:',
    gerdauStatus: 'Status interno:',
    client: 'Cliente:',
    clientStatus: 'Status externo:',
    isa: 'ISA:',
    flow: 'Tipo de consulta:',
    email: 'Correo electrónico de la persona responsable:',
    date: 'Fecha y hora de apertura: ',
    currentResponsibles: 'Responsable en este status:',
    editResponsiblesError: 'No tienes permiso para realizar esta acción.',
    legacyId: (legacyId: string) => `(Legado: ${legacyId})`,
    header: {
      title: 'Análisis técnico',
      newProductEpp: 'Nuevo Producto EPP',
      tooltip: {
        newAnalysis: 'Nueva consulta',
        norm: 'Norma',
        assets: 'Archivos adjuntos',
        refreshAnalysis: 'Recargar análisis',
        similar: 'Ver similar',
        download: 'Descargar Cotización',
        cancelQuotation: 'Anular cotización',
        content: {
          title: 'Nuevo!',
          description: 'Ahora puedes ver materiales similares',
        },
      },
      createOrderButton: 'Crear orden',
    },
    footer: {
      price: (price: string) => `Preço: ${price}/ton`,
      priceValidity: (expireDate: string) =>
        `Este precio es válido hasta el ${expireDate}. Cualquier cambio de especificación cambia el valor del producto`,
      observation: (value: string) => `Observación: ${value}`,
      downloadQuotation: 'Descargar Cotización',
    },
    tab: {
      newAttribute: 'Nueva caracteristica',
      validatedPlant: 'Esta planta ha sido validada',
      selectedPlant: 'Esta planta ha sido seleccionada',
      requestedPlant: 'Esta planta fue solicitada para análisis',
      plantSummary: 'Resumen',
      previewMessage: '* Esta planta fue elegida para ser analizada',
      processingMessage:
        'Cotización en proceso - Cuando se completa el proceso, las acciones se liberarán para la interacción.',
      messageResume: (plant: string) => `* La planta ${plant} fue seleccionada para exposición al cliente`,
      resumeTitle: (plant: string) => `Resumen del análisis técnico de ${plant}`,
      internalComment: 'Comentarios Gerdau',
      externalComment: 'Comentarios de Gerdau/Cliente',
      previewComments: (length: number) => `Comentarios (${length})`,
      attributeOrder: {
        success: 'Cambiado con éxito',
        error: 'Error al cambiar',
      },
    },
    assets: {
      assetCell: {
        sentBy: (email: string, date: string) => `Publicado por ${email} en ${date}`,
        clientVisible: 'Visible para el cliente',
      },
      assetModal: {
        title: 'Archivos adjuntos',
        noData: 'Sin adjuntos',
        selectAll: 'Seleccionar todo',
        downloadAll: (assetsAmount: number) => `Bajar (${assetsAmount})`,
        close: 'Cerrar',
        label: 'Adjunte los documentos necesarios aquí:',
      },
    },
    attributes: {
      clientComments: 'Comentarios de Gerdau/Cliente',
      attrComments: 'Comentarios sobre esta característica',
      yes: 'Sí',
      no: 'No',
      derogateStatus: {
        accepted: 'Aceptado',
        rejected: 'Rechazado',
      },
      derogateListTitle: 'Evaluaciones pendientes:',
      rangeAttribute: {
        min: (value?: number, unit?: string) => !isNaN(value) && `Min: ${value} ${unit ? unit : ''}`,
        max: (value?: number, unit?: string) => !isNaN(value) && `Max: ${value} ${unit ? unit : ''}`,
      },

      attributeCell: {
        tagAnalysis: 'Análisis',
        tagPending: 'Evaluación pendiente',
        btnAnalysis: 'Analisar',
        additionalInformation: 'Informaciones complementarias',
        gerdauComments: (commentsLength: number) =>
          `Comentarios Gerdau${commentsLength > 0 ? ` (${commentsLength})` : ''}`,
        cancel: 'Cancelar',
        save: 'Guardar',
        derogationTitle: 'Propuesta de excepción',
        derogationReason: (reason?: string) => (reason ? `Razón:||${reason}` : 'Motivo no informado'),
        derogationSuggestion: (title: string, suggestion?: string) =>
          suggestion ? `Propuesta para ${title.toLowerCase()}:||${suggestion}` : 'Propuesta no informada ',
        reject: 'Rechazar',
        accept: 'Aceptar',
        accepted: 'Aceptado',
        rejected: 'Rechazado',
        image: {
          removeImage: 'Eliminar Imagen',
          addImage: 'Añadir más imágenes',
          uploadClickButton: 'Haga clic aquí o arrastre el archivo (solo imagenes: ejemplos jpg, png, tif, etc)',
          alertImage: 'Necesitas editar para borrar esta imagen.',
        },
        form: {
          reason: 'Razón',
          generalTitle: (title: string) => `Propuesta para ${title.toLowerCase()}`,
          minTitle: (unit?: string) => `Mínimo ${unit ? `(${unit})` : ''}`,
          maxTitle: (unit?: string) => `Máximo ${unit ? `(${unit})` : ''}`,
          numberTitle: (title: string, unit: string) =>
            `Propuesta para ${title.toLowerCase()} ${unit ? `(${unit})` : ''}`,
          validator: {
            range: 'La derroga/Excepción debe contener "Mínimo" o "Máximo"',
            alertMandatory: 'Este campo es obligatorio',
          },
        },
        errorMessages: {
          reasonRequired: 'Todas las líneas marcadas con deroga/Excepción deben contener el motivo',
          invalidRange: 'El valor mínimo no puede ser mayor al valor máximo.',
          genericError: 'Algo salió mal, inténtalo nuevamente más tarde',
        },
        successMessage: (attributeName: string) => `La característica "${attributeName}" fue cambiada exitosamente`,
        errorMessage: 'Algo salió mal cuando se cambió',
        criticalLabel: 'Característica crítica',
        descriptiveAnalysis: {
          title: 'Análisis descriptivo',
          element: 'Elemento',
          amplitude: 'Amplitud',
          residualMin: 'Residual min.',
          residualMax: 'Residual máx.',
          limit: 'Límite',
          accepted: 'Aceptado',
          derogate: 'Derroga',
          suggestion: 'Sugerencia:',
          diameter: 'Calibre',
          lengthMax: 'Longitud máx.',
          lengthMin: 'Longitud min.',
          other: 'Otro',
          side: 'Lado',
          steelProcess: 'Tratamiento térmico',
          thickness: 'Espesor',
          width: 'Ancho',
        },
      },
      newAttribute: {
        title: 'Adición de nueva característica',
        label: {
          attributeName: 'Nombre de la característica',
          attributeValue: 'Valor de característica',
        },
        add: 'Para agregar',
        save: 'Guardar',
        replicateAttribute: 'Rasgo replicado para todas las plantas',
        alertSpecialChars: 'No utilice caracteres especiales como % ! * ( ) ; : @ & = + $ , / ? # [ ] < >',
        alertMandatory: 'Este campo es obligatorio',
        messages: {
          success: 'Característica añadida con éxito',
          error: 'Hubo un problema al agregar/editar la característica, intente nuevamente más tarde',
          editSuccess: 'Característica editada con éxito',
        },
      },
      editAttribute: {
        title: 'Editar característica',
        label: 'Editar',
        mandatoryField: 'Campo obligatorio',
        otherField: 'Introduzca el nuevo valor',
        save: 'Guardar',
        back: 'Volver a la lista',
        blankOption: 'Seleccionar',
        otherOption: 'OTRO',
        editSuccess: 'Característica editada con éxito',
      },
      complementaryModal: {
        title: (attributeTitle: string) => `Información complementaria de ${attributeTitle}`,
      },
      tableAttribute: {
        reason: 'Razón',
        suggestion: 'Sugerencia',
        min: 'Min',
        max: 'Max',
      },
      testMethod: {
        title: 'Tipo de prueba de esta característica',
        options: {
          other: 'Otro',
          noTestMethod: 'No enviar tipo de prueba',
        },
        errorMessages: {
          genericError: 'Algo salió mal, inténtalo nuevamente más tarde',
        },
        alertMandatory: 'Este campo es obligatorio',
        label: 'ingrese una descripción',
        submitButton: 'Guardar ensayo',
        cancelButton: 'Cancelar',
      },
      jominyAdvice:
        'Atenção: Se possível (se há fórmula aplicável, Ex. SEP 1664), propor para que jominy seja teórico no campo de derroga.',
    },
    tooltipApqp: {
      description: 'Adjunte el archivo apqp.xls aquí cuando haya terminado.',
      buttonText: 'Entendí',
    },
    tooltipSendToGerdau: {
      title: '',
      description: '¡Casi allí! Haga clic en el botón "Enviar hacia Gerdau" y complete su solicitud de cotización.',
      buttonText: 'Cerrar',
    },
    toolTipRequestAnalysis: {
      description: 'Validar al menos una planta para proceder con la Consulta de Cotización.',
      buttonText: 'Cerrar',
      steps: '1 de 2',
    },
    toolTipStatusButton: {
      description: 'Para continuar, haga clic en el botón y seleccione la opción deseada.',
      buttonText: 'Cerrar',
      steps: '2 de 2',
    },
    toolTipPendingButton: {
      description: 'Todavía tiene pendiente la evaluación de características.',
    },
    comment: {
      noData: 'No hay comentarios para mostrar',
      replicateComment: 'Replicar comentario para todas las plantas',
      placeholder: 'Escribe tu comentario aqui...',
      gerdauOnly: 'Solo visible para Gerdau',
      errors: {
        post: 'Algo salió mal al publicar este comentario, inténtalo de nuevo más tarde.',
        get: 'Algo salió mal al recuperar los comentarios, inténtalo de nuevo más tarde.',
        edit: 'Algo salió mal al editar este comentario, inténtalo de nuevo más tarde.',
        delete: 'Se ha producido un error al eliminar este comentario. Vuelve a intentarlo más tarde.',
      },
      success: {
        post: 'Comentario enviado con éxito.',
        edit: 'Comentario editado con éxito.',
        delete: 'Comentario eliminado con éxito.',
      },
      tabs: {
        internal: 'Para el equipo Gerdau',
        external: 'Para el cliente',
      },
    },
    status: {
      sendToGerdau: 'Enviar hacia Gerdau',
      seeHistory: 'Historial de consultas',
      updatedStatusTo: 'status actualizado a',
      historyModalTitle: 'Historial de consultas',
      pendingButton: 'Evaluación pendiente',
      statusModal: {
        changeStatus: (label: string) => `Mudar status para ${label}`,
        placeholder: 'Escreva seu comentário...',
        send: 'Enviar',
        emailFields: {
          sendTo: 'Enviar para',
          sendToProfile: (profile: string) => `Cambiar el status a ${RoleDisplayName.es[profile]}`,
          emailPlaceholder: 'Correo electrónico',
          emailMultiPlaceholder: 'Separar por "," para enviar a más de un correo electrónico',
          validator: {
            email: 'Correo electrónico inválido',
          },
        },
        skuField: {
          label: 'Informar el SKU creado en esta Consulta',
          labelOptional: 'Este campo no es obligatorio',
          placeholder: 'Separar por "," para enviar más de un SKU',
          alertMandatory: 'Este campo es obligatorio',
          alertSkuNumber: 'Usa solo números',
          alertSkuFormat: 'El código debe tener 9 dígitos',
        },
        skuSimilar: {
          label: 'SKU similar para Consulta',
          labelOptional: 'Este campo no es obligatorio',
          placeholder: 'Usa solo números',
          alertMandatory: 'Este campo es obligatorio',
          alertSkuNumber: 'Usa solo números',
          alertSkuFormat: 'El código debe tener 9 dígitos',
        },
        skuSimilarPrice: {
          label: 'Costo estándar de SKU similar - (R$)',
          labelOptional: 'Este campo no es obligatorio',
          alertMandatory: 'Este campo es obligatorio',
          alertSkuNumber: 'Usa solo números',
          alertSkuFormat: 'El código debe tener 9 dígitos',
        },
        skuObservation: {
          label: 'Notas sobre SKU similares',
          labelOptional: 'Este campo no es obligatorio',
          placeholder: 'Usa solo números',
          alertMandatory: 'Este campo es obligatorio',
          alertSkuNumber: 'Usa solo números',
          alertSkuFormat: 'El código debe tener 9 dígitos',
        },
        observationField: {
          labelGerdau: 'Nota para el equipo Gerdau',
          labelClient: 'Nota al cliente',
        },
      },
      peddingModal: {
        title: 'Enviar a Gerdau',
        send: 'Enviar a Gerdau',
        partNumberQuestion: '¿Este presupuesto ya tiene números de pieza (PN) asociados?',
        options: {
          yes: 'Sí',
          no: 'No',
        },
        partNumberField: {
          label: 'Informar el Part Number',
          alertMandatory: 'Este campo es obligatorio',
        },
        observationField: {
          placeholder: 'Agregue otras notas si es necesario. (Opcional)',
          alertMandatory: 'Este campo es obligatorio',
        },
      },
      cancelModal: {
        title: 'Anular cotización',
        text: '¿Realmente desea anular esta cotización?',
        obervationLabel: 'Razón',
        alertMandatory: 'Este campo es obligatorio',
        btnSubmit: 'Sí, anular cotización',
        btnCancel: 'No',
        btnHeader: 'Anular',
      },
    },
    components: {
      downloadPdf: {
        title: 'Seleccione las plantas que se colocarán en el PDF',
        generatePdf: 'Generar PDF',
        cancel: 'Cancelar',
        errors: {
          selectAtLeastOne: 'Elija al menos una planta para generar el PDF',
        },
      },
      about: {
        optionsButton: 'Opciones',
        analysis: 'Consulta',
        editAnalysis: 'Editar',
        duplicateAnalysis: 'Duplicar',
        share: 'Para compartir',
        generatePdf: 'Generar PDF',
        generateTitlePdf: 'Gerar PDF de consulta',
        sendToBagg: 'Enviar a BAGG',
      },
      messages: {
        success: 'El estado cambió con éxito',
        alert: 'Algo salió mal:',
        sendToBaggSuccess: 'Consulta reenviado a Bagg con éxito',
        forbidden: 'No tienes permiso para acceder a esta cotización.',
      },
      attachments: {
        no: 'Sí, todavía no lo he adjuntado',
        yes: 'Sí, ya está en los archivos adjuntos',
      },
      observationStatusLabel: 'Notas de los clientes',
      buttonMES: 'Realice un análisis CF usando datos MES',
    },
    modal: {
      similarMaterials: {
        title: 'Materiales similares',
        legend: '** Esta característica difiere de la solicitada en la consulta.',
        noData: 'No se encontraron artículos similares para este caso.',
        cancel: 'Cancelar',
        filename: 'Materiales similares',
        loading: 'Cargando...',
      },
    },
    unfinishedAdvice: {
      text: 'Tienes 1 cotización en curso, ¿quieres recuperarla?',
      btnLabel: 'Recuperar consulta',
    },
    error: 'Hubo un error al realizar la acción, intente nuevamente más tarde',
    attributeDerogateBadges: {
      accepted: { label: 'Aceptado', color: 'success' },
      rejected: { label: 'Rechazado', color: 'warning' },
    },
    apqpPeOption: {
      notAttach: 'Sí, todavía no lo he adjuntado.',
      yes: 'Sí',
    },
    responsibles: {
      add: 'Agregar otro responsable',
      remove: 'Quitar responsable',
    },
    branding: {
      edit: 'Editar marca',
      title: 'Línea de producto',
      error: 'Error al guardar la marca.',
      success: 'Marca cambiada con éxito.',
      tooltip: 'Debe asignar una línea de producto para cambiar el estado.',
    },
    skuModalstatus: {
      btnAdd: 'Añadir SKU',
      title: 'SKUs',
      btnSend: 'Enviar',
      skuField: {
        label: 'Informar el SKU creado en esta Consulta',
        placeholder: 'Separar por "," para enviar más de un SKU',
        alertMandatory: 'Este campo es obligatorio',
        alertSkuNumber: 'Usa solo números',
        alertSkuFormat: 'El código debe tener 9 dígitos',
      },
    },
  },
};
