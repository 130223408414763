/* eslint-disable complexity */
import { Field, Steps } from '../components/form-flow-fields.container';
import { FormStrings } from '../form.string';
import { isValidCNPJ } from './checkCNPJ';
import { ClientBpsData, FieldsData, FormResultData } from './mapPayloadAnalysisOld.dto';

export const mapFields = res => {
  const orderedFields = Object.values(res).sort((a: Field, b: Field) => a.order - b.order);

  return orderedFields.reduce((acc, item: Field) => {
    acc[item.key] = { ...item, loading: false };
    return acc;
  }, {});
};

export const conditionalAways = [
  {
    key: 'AWAYS',
    value: 'AWAYS',
    and: [],
    or: [],
  },
];

export const mapFormFlow = (response: Steps, step: string) => {
  return {
    [step]: {
      key: step,
      questions: {
        key: response.key,
        label: response.label,
        question: response.question,
        fields: mapFields(response.fields),
      },
    },
  };
};

export const mapSteelFlow = (response, otherOptions) => {
  const steelList = response
    ? response.map(item => {
        return {
          key: item.replaceAll(' ', '_'),
          label: item,
          value: item.replaceAll(' ', '_'),
          conditional: conditionalAways,
        };
      })
    : [];
  otherOptions && steelList.push(otherOptions);

  return steelList;
};

export const mapLengthToleranceFlow = response => {
  const attributes = response.PRODUCT.map(item => {
    if (item.key === 'LENGTH_TOLERANCE') {
      return { key: item.key, value: `${item.value.min} a ${item.value.max}` };
    } else if (item.key === 'SHORTER_SIZE') {
      return { key: item.key, value: `${item.value.min} a ${item.value.max}` };
    } else if (item.key === 'ACCEPTED_SHORT') {
      if (item.value) {
        return { key: item.key, value: 'Sim', conditional: conditionalAways };
      } else {
        return { key: item.key, value: 'Não', conditional: conditionalAways };
      }
    } else {
      return { key: item.key, value: `${item.value}` };
    }
  });
  return attributes;
};

export const mapLengthMultiplesFlow = response => {
  return { min: `${response.PRODUCT[0].value.min}`, max: `${response.PRODUCT[0].value.max}` };
};

export const mapAdditionalAttributesList = response => {
  const entries = Object.entries(response.ADDITIONAL.questions.fields);

  const fields = entries.map((field: [string, Field]) => {
    return { key: field[1].key, label: field[1].label };
  });

  return fields;
};

export const mapBendingFlow = response => {
  const attributes = response[0].PRODUCT.map(item => {
    if (item.key === 'DIAMETER_TOLERANCE') {
      return {
        key: item.key,
        value: `${item.value.min} a ${item.value.max}`,
      };
    } else {
      return { key: item.key, value: item.value };
    }
  });
  return attributes;
};

export const mapNLU = (response: FieldsData[]) => {
  const nluAttributes = [];
  response.forEach(item => {
    if (item.key === 'PRODUCT_REVISION_NORM') {
      const selectedValue = item.options.find(op => op.key === item.value[0]);
      nluAttributes.push({ ...item, value: [selectedValue.label] });
    } else if (item.key === 'PRODUCT') {
      const selectedValue = item.options.find(op => op.key === item.value[0]);
      nluAttributes.push({ ...item, value: selectedValue });
    } else if (item.key === 'CHEMICAL_COMPOSITION') {
      if (item.value.length > 0) {
        nluAttributes.push({ ...item });
      }
    } else {
      nluAttributes.push({ ...item });
    }
  });

  return nluAttributes;
};

export const mapDraft = (response: FieldsData[]) => {
  const nluAttributes = [];

  response.forEach(item => {
    function convertRuleOptionsSpecificSpecification(options) {
      return options.map(option => {
        return {
          key: option.key.replaceAll('_', ' '),
          label: option.label,
          value: option.value,
          conditional: conditionalAways,
        };
      });
    }
    function convertRuleValueSpecificSpecification(val) {
      return {
        key: val.key.replaceAll('_', ' '),
        label: val.label,
        value: val.value,
      };
    }
    if (item.type === 'range') {
      Object.assign(item, {
        min: `${item.value.min}`,
        max: `${item.value.max}`,
        value: `${item.value.min} a ${item.value.max}`,
      });
    }
    if (item.key === 'SPECIFIC_SPECIFICATION') {
      nluAttributes.push({
        ...item,
        value: convertRuleValueSpecificSpecification(item.value),
        options: convertRuleOptionsSpecificSpecification(item.options),
      });
    } else {
      nluAttributes.push({ ...item });
    }
  });

  return nluAttributes;
};

export const mapSpecificSpecification = response => {
  const convertedSpecifications = [];
  response.forEach(norm => {
    norm.forEach(item => {
      if (item.questionKey === 'SPECIFIC_SPECIFICATION') {
        convertedSpecifications.push(item.options[0]);
      }
    });
  });

  function removeDuplicated(items) {
    const seen = new Set();
    const result = [];

    for (const item of items) {
      const jsonString = JSON.stringify(item);
      if (!seen.has(jsonString)) {
        seen.add(jsonString);
        result.push({ ...item });
      }
    }
    return result;
  }
  const finalElements = removeDuplicated(convertedSpecifications);
  return finalElements;
};

export const attributesToDelete = (field: string, additionalFields?: string[]) => {
  let attributesList = [];
  if (field === 'CLIENT') {
    attributesList = [
      { key: 'CLIENT_CODE', step: 'CLIENT', hideQuestion: 'YES' },
      { key: 'CORPORATE_CODE', step: 'CLIENT', hideQuestion: 'YES' },
      { key: 'FLOW', step: 'CLIENT' },
      { key: 'STEEL_NAME', step: 'STEEL' },
      { key: 'SPECIFIC_SPECIFICATION', step: 'STEEL', hideQuestion: 'YES' },
      { key: 'CHEMICAL_COMPOSITION', step: 'STEEL', hideQuestion: 'YES' },
      { key: 'SPECIFIC_SPECIFICATION_NAME', step: 'STEEL', hideQuestion: 'YES' },
      { key: 'CHECK_FILE', step: 'STEEL', hideQuestion: 'YES' },
    ];
  } else if (field === 'PRODUCT' || field === 'SPECIFIC_SPECIFICATION') {
    attributesList = [
      { key: 'SPECIFICATION_PRODUCT', step: 'PRODUCT' },
      { key: 'GERDAU_PROCESS_WIRE_ROD', step: 'PRODUCT', hideQuestion: 'YES' },
      { key: 'GERDAU_PROCESS_WIRE', step: 'PRODUCT', hideQuestion: 'YES' },
      { key: 'GERDAU_PROCESS_BAR', step: 'PRODUCT', hideQuestion: 'YES' },
      { key: 'GERDAU_PROCESS_BLOCK', step: 'PRODUCT', hideQuestion: 'YES' },
      { key: 'GERDAU_PROCESS_INGOT', step: 'PRODUCT', hideQuestion: 'YES' },
      { key: 'GERDAU_PROCESS_BILLET', step: 'PRODUCT', hideQuestion: 'YES' },
      { key: 'SHAPE_WIRE_ROD', step: 'PRODUCT', hideQuestion: 'YES' },
      { key: 'SHAPE_WIRE', step: 'PRODUCT', hideQuestion: 'YES' },
      { key: 'SHAPE_BAR', step: 'PRODUCT', hideQuestion: 'YES' },
      { key: 'SHAPE_BLOCK', step: 'PRODUCT', hideQuestion: 'YES' },
      { key: 'SHAPE_BILLET', step: 'PRODUCT', hideQuestion: 'YES' },
      { key: 'SURFACE_FINISH', step: 'PRODUCT', hideQuestion: 'YES' },
      { key: 'HEAT_TREATMENT_WIRE', step: 'PRODUCT', hideQuestion: 'YES' },
      { key: 'HEAT_TREATMENT_BAR', step: 'PRODUCT', hideQuestion: 'YES' },
      { key: 'HEAT_TREATMENT_WIRE_ROD', step: 'PRODUCT', hideQuestion: 'YES' },
      { key: 'HEAT_TREATMENT_BLOCK', step: 'PRODUCT', hideQuestion: 'YES' },
      { key: 'HEAT_TREATMENT_INGOT', step: 'PRODUCT', hideQuestion: 'YES' },
      { key: 'HEAT_TREATMENT_BILLET', step: 'PRODUCT', hideQuestion: 'YES' },
      { key: 'DIAMETER', step: 'PRODUCT', hideQuestion: 'YES' },
      { key: 'WIDTH', step: 'PRODUCT', hideQuestion: 'YES' },
      { key: 'THICKNESS', step: 'PRODUCT', hideQuestion: 'YES' },
      { key: 'SIDE', step: 'PRODUCT', hideQuestion: 'YES' },
      { key: 'LENGTH', step: 'PRODUCT', hideQuestion: 'YES' },
      { key: 'INGOT_WEIGHT', step: 'PRODUCT', hideQuestion: 'YES' },
      { key: 'CORNER_RADIUS', step: 'PRODUCT', hideQuestion: 'YES' },
      { key: 'LENGTH_TOLERANCE', step: 'PRODUCT', hideQuestion: 'YES' },
      { key: 'ACCEPTED_SHORT', step: 'PRODUCT', hideQuestion: 'YES' },
      { key: 'SHORTER_PERCENTAGE', step: 'PRODUCT', hideQuestion: 'YES' },
      { key: 'SHORTER_SIZE', step: 'PRODUCT', hideQuestion: 'YES' },
      { key: 'SHORTER_PACKAGE', step: 'PRODUCT', hideQuestion: 'YES' },
      { key: 'BENDING', step: 'PRODUCT', hideQuestion: 'YES' },
      { key: 'DIAMETER_TOLERANCE', step: 'PRODUCT', hideQuestion: 'YES' },
      { key: 'ADDITIONAL_DIAMETER', step: 'PRODUCT', hideQuestion: 'YES' },
      { key: 'ADDITIONAL_SIDE', step: 'PRODUCT', hideQuestion: 'YES' },
    ];
    additionalFields.forEach(item => attributesList.push({ key: item, step: 'PRODUCT', hideQuestion: 'YES' }));
    //additionalAmounts.map(item => attributesList.push({ key: item, step: 'GOAL' }));
    if (field === 'SPECIFIC_SPECIFICATION') {
      attributesList.push({ key: 'PRODUCT', step: 'PRODUCT' });
    }
  } else if (field === 'STEEL_NAME') {
    attributesList = [
      //{ key: 'CHEMICAL_COMPOSITION', step: 'STEEL' },
      { key: 'SPECIFIC_SPECIFICATION', step: 'STEEL' },
      { key: 'SPECIFICATION_PRODUCT', step: 'PRODUCT', hideQuestion: 'YES' },
      { key: 'NORM_INTERNATIONAL', step: 'STEEL', hideQuestion: 'YES' },
      { key: 'SPECIFIC_SPECIFICATION_REVISION_NORM', step: 'STEEL', hideQuestion: 'YES' },
      { key: 'SPECIFICATION_REVISION', step: 'STEEL', hideQuestion: 'YES' },
      { key: 'SPECIFICATION_DATE', step: 'STEEL', hideQuestion: 'YES' },
      { key: 'SPECIFIC_SPECIFICATION_NAME', step: 'STEEL', hideQuestion: 'YES' },
    ];
  } else if (field === 'SPECIFICATION_PRODUCT') {
    attributesList = [
      { key: 'DIAMETER', step: 'PRODUCT', hideQuestion: 'YES' },
      { key: 'WIDTH', step: 'PRODUCT', hideQuestion: 'YES' },
      { key: 'THICKNESS', step: 'PRODUCT', hideQuestion: 'YES' },
      { key: 'SIDE', step: 'PRODUCT', hideQuestion: 'YES' },
      { key: 'INGOT_WEIGHT', step: 'PRODUCT', hideQuestion: 'YES' },
      { key: 'CORNER_RADIUS', step: 'PRODUCT', hideQuestion: 'YES' },
      { key: 'LENGTH', step: 'PRODUCT', hideQuestion: 'YES' },
      { key: 'LENGTH_TOLERANCE', step: 'PRODUCT', hideQuestion: 'YES' },
      { key: 'ACCEPTED_SHORT', step: 'PRODUCT', hideQuestion: 'YES' },
      { key: 'SHORTER_PERCENTAGE', step: 'PRODUCT', hideQuestion: 'YES' },
      { key: 'SHORTER_SIZE', step: 'PRODUCT', hideQuestion: 'YES' },
      { key: 'SHORTER_PACKAGE', step: 'PRODUCT', hideQuestion: 'YES' },
      { key: 'BENDING', step: 'PRODUCT', hideQuestion: 'YES' },
      { key: 'DIAMETER_TOLERANCE', step: 'PRODUCT', hideQuestion: 'YES' },
      { key: 'ADDITIONAL_DIAMETER', step: 'PRODUCT', hideQuestion: 'YES' },
      { key: 'ADDITIONAL_SIDE', step: 'PRODUCT', hideQuestion: 'YES' },
    ];
    additionalFields.forEach(item => attributesList.push({ key: item, step: 'PRODUCT', hideQuestion: 'YES' }));
    //additionalAmounts.map(item => attributesList.push({ key: item, step: 'GOAL' }));
  } else if (
    field === 'DIAMETER' ||
    field === 'SIDE' ||
    field === 'LENGTH' ||
    field === 'WIDTH' ||
    field === 'THICKNESS' ||
    field === 'AMOUNT'
  ) {
    additionalFields.forEach(item => attributesList.push({ key: item, step: 'PRODUCT', hideQuestion: 'YES' }));
    //additionalAmounts.map(item => attributesList.push({ key: item, step: 'GOAL' }));
  } else if (field === 'POST_FILE') {
    attributesList = [{ key: 'SPECIFIC_SPECIFICATION_NAME', step: 'STEEL', hideQuestion: 'YES' }];
  }
  return attributesList;
};

interface Questions {
  key: string;
  questions: {
    fields: any;
  };
}

interface PayloadData {
  title: string;
  type: string; ////TECHNICAL_ANALYSIS / PRICE_CONSULTATION,
  flow: string;
  client: {
    bp?: string;
    corporateGroup?: string;
    cnpj?: number;
    nif?: string;
  };
  specification: {
    isNew: boolean;
    isInternational?: boolean;
    date?: string;
    name?: string;
    type?: string;
    revision?: string;
  };
  product?: {
    steelName: string;
    type: string;
    shape: string;
    surfaceFinish: string;
    gerdauProcess: string;
  };
  products?: {
    diameter?: number;
    sequence?: number; //1, 2, 3 etc...
    side?: number;
    width?: number;
    length?: { min: number; max: number };
    thickness?: number;
  }[];
  questions?: {
    questionId: string;
    flow: string;
    step: string;
    key: string;
    label: string;
    type: string;
    readBy: string;
    value: any;
    unit?: {
      symbol: string;
      description: string;
      allowed: [
        {
          symbol: string;
          description: string;
        },
      ];
    };
  }[];
}

export const mapPayloadAnalysis = (data: FormResultData, newFieldAdd?: FieldsData[]) => {
  const attributes = [];
  function convertKeys(key) {
    if (key !== null && key !== undefined) {
      switch (key) {
        case key.includes('GERDAU_PROCESS_'):
          return 'GERDAU_PROCESS';
        case key.includes('SHAPE_'):
          return 'SHAPE';
        case key.includes('HEAT_TREATMENT_'):
          return 'HEAT_TREATMENT';
        default:
          return key;
      }
    }
  }

  const newFields = newFieldAdd
    ? newFieldAdd.map(item => {
        return {
          flow: data.CLIENT.questions.fields.FLOW.value.key,
          questionId: item.id,
          options: item?.options ? convertOptions(item.options) : [],
          step: item.step,
          key: convertKeys(item.key),
          label: item.label,
          type: item.type,
          readBy: item?.readBy ? item.readBy.toLowerCase() : null,
          value: JSON.stringify(item.value) !== '{}' ? item.value : null,
          unit: item?.unit ? item.unit : null,
          conditional: item?.conditional ? item.conditional : [],
          permissions: item.permissions,
        };
      })
    : [];

  function convertValue(value, type) {
    if (value === undefined || value === null) {
      return null;
    }
    if (type === 'range') {
      const val = value.split(' a ');

      return { min: parseFloat(val[0]), max: parseFloat(val[1]) };
    } else if (
      type === 'autocomplete' ||
      type === 'text' ||
      type === 'date' ||
      type === 'table' ||
      type === 'textArray' ||
      type === 'checkbox' ||
      type === 'select' ||
      type === 'file'
    ) {
      return value;
    } else if (type === 'number') {
      return parseFloat(value);
    } else {
      if (value?.value) {
        if (typeof value?.value === 'number') {
          return {
            key: value.key.replaceAll(' ', '_') ? value.key.replaceAll(' ', '_') : value.key,
            label: value.label,
            value: value.value,
          };
        } else {
          return {
            key: value.key.replaceAll(' ', '_') ? value.key.replaceAll(' ', '_') : value.key,
            label: value.label,
            value: value.value.replaceAll(' ', '_') ? value.value.replaceAll(' ', '_') : value.value,
          };
        }
      } else {
        return value;
      }
    }
  }

  function transformAttributes(input) {
    const output = [];
    const diameterValues = input.diameter.map(item => parseFloat(item.value));
    const sideValues = input.side.map(item => parseFloat(item.value));
    const lengthValues = input.length.map(item => (item?.value ? convertValue(item.value, 'range') : null));
    const widthValues = input.width.map(item => parseFloat(item.value));
    const thicknessValues = input.thickness.map(item => parseFloat(item.value));
    const amountValues = input.amount.map(item => parseFloat(item.value));

    for (let i = 0; i < Math.max(sideValues.length, lengthValues.length); i++) {
      output.push({
        sequence: i + 1,
        diameter: diameterValues[i] || null,
        side: sideValues[i] || null,
        width: widthValues[i] || null,
        length: lengthValues[i] || null,
        thickness: thicknessValues[i] || null,
        amount: amountValues[i] || null,
      });
    }
    return output;
  }

  function convertOptions(options) {
    return options.map(item => {
      return {
        key: item.key.replaceAll(' ', '_'),
        label: item.label,
        value: item.value,
        conditional: item.conditional,
      };
    });
  }

  const title = `${
    data?.PRODUCT?.questions?.fields?.PRODUCT?.label
      ? data?.PRODUCT?.questions?.fields?.PRODUCT?.label
      : data.CLIENT.questions.fields.FLOW.value.label
  }`;

  const payload: PayloadData = {
    title: title,
    type: 'PRICE_CONSULTATION',
    flow: data.CLIENT.questions.fields.FLOW.value.key,
    client: {
      bp: data.CLIENT.questions.fields.CLIENT_CODE.value,
      corporateGroup: data.CLIENT.questions.fields.CORPORATE_CODE.value,
    },
    specification: {
      isNew: false,
      isInternational: false,
    },
    questions: null,
  };
  if (data?.CLIENT?.questions?.fields?.CNPJ?.value) {
    if (isValidCNPJ(data.CLIENT.questions.fields.CNPJ.value)) {
      payload.client.cnpj = data.CLIENT.questions.fields.CNPJ.value;
    } else {
      payload.client.nif = data.CLIENT.questions.fields.CNPJ.value;
    }
  }
  const shape = [];
  const diameter = [];
  const side = [];
  const width = [];
  const length = [];
  const thickness = [];
  const amount = [];
  Object.entries(data).forEach((steps: [string, Questions]) => {
    const [_, step] = steps;

    return (
      step?.questions &&
      step?.key &&
      Object.entries(step.questions.fields).map((field: [string, Field]) => {
        const [, object] = field;

        object?.key && object.key.includes('SHAPE') && object?.value && shape.push(object);
        object?.key &&
          object.key.includes('DIAMETER') &&
          object.key !== 'DIAMETER_TOLERANCE' &&
          object?.value &&
          diameter.push(object);
        object?.key && object.key.includes('SIDE') && object?.value && side.push(object);
        object?.key && object.key.includes('WIDTH') && object?.value && width.push(object);
        object?.key &&
          object.key.includes('LENGTH') &&
          object.key !== 'LENGTH_TOLERANCE' &&
          object?.value &&
          length.push(object);
        object?.key && object.key.includes('THICKNESS') && object?.value && thickness.push(object);
        object?.key && object.key.includes('AMOUNT') && object?.value && amount.push(object);

        if (object.value && object.key && object.step && object.id) {
          attributes.push({
            flow: data.CLIENT.questions.fields.FLOW.value.key,
            questionId: object.id,
            options: object?.options ? convertOptions(object.options) : [],
            step: object.step,
            key: convertKeys(object.key),
            label: object?.label ? object.label : object.key,
            type: object.type,
            readBy: object?.readBy ? object.readBy.toLowerCase() : null,
            value: JSON.stringify(object.value) !== '{}' ? convertValue(object.value, object.type) : '',
            unit: object?.unit ? object.unit : null,
            conditional: object?.conditional ? object.conditional : [],
            permissions: object.permissions,
            callback: object.callback,
          });
        }
      })
    );
  });

  newFields.forEach(field => {
    const valueDefault = attributes.find(attr => attr.key === field.key);

    if (valueDefault?.key) {
      attributes.find(attr => attr.key === valueDefault.key).value =
        JSON.stringify(field.value) !== '{}' ? convertValue(field.value, field.type) : '';
      attributes.find(attr => attr.key === valueDefault.key).unit = field?.unit ? field.unit : null;
    } else {
      const newAttr = {
        flow: data.CLIENT.questions.fields.FLOW.value.key,
        questionId: valueDefault?.id || field?.questionId,
        options: valueDefault?.options ? convertOptions(valueDefault.options) : [],
        step: valueDefault?.step || field?.step,
        key: convertKeys(valueDefault?.key || field?.key),
        label: valueDefault?.label || field?.label,
        type: valueDefault?.type || field?.type,
        readBy: valueDefault?.readBy
          ? valueDefault.readBy.toLowerCase()
          : field?.readBy
          ? field.readBy.toLowerCase()
          : null,
        value: JSON.stringify(field.value) !== '{}' ? convertValue(field.value, field.type) : '',
        unit: field?.unit ? field.unit : null,
        conditional: valueDefault?.conditional ? valueDefault.conditional : field?.conditional ? field.conditional : [],
        permissions: valueDefault?.permissions || field?.permissions,
      };
      attributes.push(newAttr);
    }
  });

  const productFields = data.PRODUCT?.questions?.fields;
  const steelFields = data.STEEL?.questions?.fields;

  payload.product = steelFields &&
    productFields && {
      steelName:
        typeof steelFields.STEEL_NAME?.value === 'string'
          ? steelFields.STEEL_NAME.value
          : steelFields.STEEL_NAME?.value?.key,
      type: productFields.PRODUCT?.value?.key,
      shape: productFields[`SHAPE_${productFields.PRODUCT?.value?.key}`]?.value?.key,
      surfaceFinish: productFields.SURFACE_FINISH?.value?.key,
      gerdauProcess: productFields[`GERDAU_PROCESS_${productFields.PRODUCT?.value?.key}`]?.value?.key,
    };

  const sequence = [];
  const attr = transformAttributes({ diameter, side, width, length, thickness, amount, sequence });
  const finalAttributes = attr
    .map(obj => {
      return Object.fromEntries(Object.entries(obj).filter(([, value]) => value !== null));
    })
    .filter(obj => Object.keys(obj).length > 0);

  payload.products = finalAttributes;
  payload.questions = attributes;

  return payload;
};

export const initialAditionalDiameter = (language: string) => {
  const strings = FormStrings[language].modalDiameter.bitola;

  return [
    {
      label: `${strings}`,
      diameter: null,
      side: null,
      thickness: null,
      width: null,
      length: null,
      amount: null,
    },
    {
      label: `${strings} 2`,
      diameter: null,
      side: null,
      thickness: null,
      width: null,
      length: null,
      amount: null,
    },
  ];
};

export const convertCNPJ = value => {
  return value.replace(/\D/g, '');
};

export const mapClientBps = (response: Partial<ClientBpsData>[]) => {
  return response.map(item => {
    return {
      key: item.bp,
      label: `${item.name} - ${item.city} - ${item.country}`,
      value: item.name,
      conditional: conditionalAways,
    };
  });
};
export const chemicalCompositionDefaultValue = [
  {
    element: 'C',
    value: {
      min: 0,
      max: 99,
    },
    unit: '%',
  },
  {
    element: 'Mn',
    value: {
      min: 0,
      max: 99,
    },
    unit: '%',
  },
  {
    element: 'Si',
    value: {
      min: 0,
      max: 99,
    },
    unit: '%',
  },
];
