import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Button } from '@atomic/atm.button';
import { Divisor } from '@atomic/atm.divisor';
import { FaIcon } from '@atomic/atm.fa-icon';
import { H1 } from '@atomic/atm.typography';
import { Table, TH, TR } from '@atomic/mol.table';
import { Hbox } from '@atomic/obj.box';
import { Form, FormData } from '@atomic/obj.form';
import { Col, Grid, Row, VSeparator } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
import { FormContext } from '../form.context';
import { FormStrings } from '../form.string';
import { DiameterRow } from './modal-diameter-row.component';
import { DiameterRowWrapper } from './modal-diameter.component.style';
import { JominyContentWrapperStyled, LinkText } from './modal-jominy.component.style';

interface ModalDiameterProps {
  open: boolean;
  submitModals: (stepKey: string, key: string, values: any) => void;
  data?: Element[];
}
export interface AdditionalDiameterData {
  ADDITIONAL_DIAMETER: any[];
  DIAMETER: string[];
  AMOUNT: string[];
  LENGTH: { min: string; max: string }[];
  THICKNESS: string[];
  WIDTH: string[];
  SIDE: string[];
}

interface AdditionalDiameterValues {
  label?: string;
  diameter?: number;
  side?: number;
  thickness?: number;
  width?: number;
  length?: string;
  min?: number;
  max?: number;
  amount?: number;
}

export const ModalDiameter: React.FC<ModalDiameterProps> = props => {
  const {
    setModalToOpen,
    formResult,
    setFormResult,
    additionalDiameters,
    setAdditionalDiameters,
    handleUpdateDraft,
    draftQuote,
  } = React.useContext(FormContext);
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings = FormStrings[userInfo.language].modalDiameter;
  //const [additionalDiameters, setAdditionalDiameters] = React.useState(initialAditionalDiameter());
  const [opened, setOpened] = React.useState(props.open);
  const [numBitolas, setNumBitolas] = React.useState(1);
  const contentRef = React.useRef(null);
  const noLength = ['WIRE_ROD', 'WIRE', 'INGOT'].includes(formResult?.PRODUCT?.questions?.fields?.PRODUCT?.value?.key);
  const product = formResult?.PRODUCT?.questions?.fields?.PRODUCT?.value?.key;
  const shape = product === 'INGOT' ? 'INGOT' : formResult.PRODUCT?.questions?.fields[`SHAPE_${product}`]?.value.key;

  const handleSubmit = async (formData: FormData<AdditionalDiameterData>) => {
    if (Object.keys(formData.error).length > 0) {
      return;
    }

    const attributesToReset = Object.keys(formResult.PRODUCT.questions?.fields).filter(
      item =>
        (item.includes('DIAMETER') ||
          item.includes('SIDE') ||
          item.includes('LENGTH') ||
          item.includes('WIDTH') ||
          item.includes('THICKNESS') ||
          item.includes('AMOUNT')) &&
        item !== 'LENGTH_TOLERANCE' &&
        item !== 'DIAMETER_TOLERANCE' &&
        item !== 'ADDITIONAL_DIAMETER' &&
        item !== 'ADDITIONAL_SIDE',
    );
    if (attributesToReset.length > 0) {
      attributesToReset.forEach((item: string) => {
        if (formResult.PRODUCT?.questions?.fields[item]?.value) {
          setFormResult(prevValue => ({
            ...prevValue,
            PRODUCT: {
              ...prevValue.PRODUCT,
              questions: {
                ...prevValue.PRODUCT.questions,
                fields: {
                  ...prevValue.PRODUCT.questions.fields,
                  [item]: {
                    ...prevValue.PRODUCT.questions.fields[item],
                    value: null,
                    display: false,
                  },
                },
              },
            },
          }));
        }
      });
    }

    const arrayUpdate = [];
    const array = [];
    const formResultUpdate: { [key: string]: any } = {};

    formData.data.ADDITIONAL_DIAMETER.forEach((item, index) => {
      array.push({
        label: item.label,
        amount: formData?.data?.AMOUNT ? parseFloat(formData?.data?.AMOUNT[index]) : null,
        diameter: formData?.data?.DIAMETER ? parseFloat(formData?.data?.DIAMETER[index]) : null,
        side: formData?.data?.SIDE ? parseFloat(formData?.data?.SIDE[index]) : null,
        thickness: formData?.data?.THICKNESS ? parseFloat(formData?.data?.THICKNESS[index]) : null,
        width: formData?.data?.WIDTH ? parseFloat(formData?.data?.WIDTH[index]) : null,
        length: formData?.data?.LENGTH
          ? `${formData.data.LENGTH[index].min} a ${formData.data.LENGTH[index].max}`
          : null,
      });
    });

    setAdditionalDiameters(array);

    if (formData?.data?.DIAMETER) {
      formData.data.DIAMETER.forEach((item, index) => {
        arrayUpdate.push({
          key: index === 0 ? 'DIAMETER' : `DIAMETER_${index + 1}`,
          step: 'PRODUCT',
          label: index === 0 ? strings.bitola : `${strings.bitola} ${index + 1}`,
          type: 'number',
          permissions: { visible: true, editable: true },
          id: formResult.PRODUCT.questions.fields.DIAMETER.id,
          display: index === 0 ? true : false,
          value: parseFloat(item),
          readBy: 'user',
          unit: formResult.PRODUCT.questions.fields.DIAMETER.unit,
          userType: ['GERDAU', 'CLIENT'],
          flow: formResult?.CLIENT?.questions?.fields?.FLOW?.value?.value,
        });

        Object.assign(formResultUpdate, {
          [index === 0 ? 'DIAMETER' : `DIAMETER_${index + 1}`]: {
            key: index === 0 ? 'DIAMETER' : `DIAMETER_${index + 1}`,
            step: 'PRODUCT',
            label: index === 0 ? strings.bitola : `${strings.bitola} ${index + 1}`,
            type: 'number',
            permissions: { visible: true, editable: true },
            id: formResult.PRODUCT.questions.fields.DIAMETER.id,
            display: index === 0 ? true : false,
            value: parseFloat(item),
            readBy: 'user',
            unit: formResult.PRODUCT.questions.fields.DIAMETER.unit,
            userType: ['GERDAU', 'CLIENT'],
            flow: formResult?.CLIENT?.questions?.fields?.FLOW?.value?.value,
          },
        });
        if (index === 0) {
          Object.assign(formResultUpdate.DIAMETER, {
            conditional: formResult.PRODUCT.questions.fields.DIAMETER.conditional,
            detail: formResult.PRODUCT.questions.fields.DIAMETER.detail,
            mandatory: formResult.PRODUCT.questions.fields.DIAMETER.mandatory,
            order: formResult.PRODUCT.questions.fields.DIAMETER.order,
            placeholder: formResult.PRODUCT.questions.fields.DIAMETER.placeholder,
            question: formResult.PRODUCT.questions.fields.DIAMETER.question,
          });
        }
      });
    }

    if (formData?.data?.SIDE) {
      formData.data.SIDE.forEach((item, index) => {
        arrayUpdate.push({
          key: index === 0 ? 'SIDE' : `SIDE_${index + 1}`,
          step: 'PRODUCT',
          label: index === 0 ? strings.side : `${strings.side} ${index + 1}`,
          type: 'number',
          permissions: { visible: true, editable: true },
          id: formResult.PRODUCT.questions.fields.SIDE.id,
          display: index === 0 ? true : false,
          value: parseFloat(item),
          readBy: 'user',
          unit: formResult.PRODUCT.questions.fields.SIDE.unit,
          userType: ['GERDAU', 'CLIENT'],
          flow: formResult?.CLIENT?.questions?.fields?.FLOW?.value?.value,
        });

        Object.assign(formResultUpdate, {
          [index === 0 ? 'SIDE' : `SIDE_${index + 1}`]: {
            key: index === 0 ? 'SIDE' : `SIDE_${index + 1}`,
            step: 'PRODUCT',
            label: index === 0 ? strings.side : `${strings.side} ${index + 1}`,
            type: 'number',
            permissions: { visible: true, editable: true },
            id: formResult.PRODUCT.questions.fields.SIDE.id,
            display: index === 0 ? true : false,
            value: parseFloat(item),
            readBy: 'user',
            unit: formResult.PRODUCT.questions.fields.SIDE.unit,
            userType: ['GERDAU', 'CLIENT'],
            flow: formResult?.CLIENT?.questions?.fields?.FLOW?.value?.value,
          },
        });
        if (index === 0) {
          Object.assign(formResultUpdate.SIDE, {
            conditional: formResult.PRODUCT.questions.fields.SIDE.conditional,
            detail: formResult.PRODUCT.questions.fields.SIDE.detail,
            mandatory: formResult.PRODUCT.questions.fields.SIDE.mandatory,
            order: formResult.PRODUCT.questions.fields.SIDE.order,
            placeholder: formResult.PRODUCT.questions.fields.SIDE.placeholder,
            question: formResult.PRODUCT.questions.fields.SIDE.question,
          });
        }
      });
    }

    if (formData?.data?.WIDTH) {
      formData.data.WIDTH.forEach((item, index) => {
        arrayUpdate.push({
          key: index === 0 ? 'WIDTH' : `WIDTH_${index + 1}`,
          step: 'PRODUCT',
          label: index === 0 ? strings.largura : `${strings.largura} ${index + 1}`,
          type: 'number',
          permissions: { visible: true, editable: true },
          id: formResult.PRODUCT.questions.fields.WIDTH.id,
          display: index === 0 ? true : false,
          value: parseFloat(item),
          readBy: 'user',
          unit: formResult.PRODUCT.questions.fields.WIDTH.unit,
          userType: ['GERDAU', 'CLIENT'],
          flow: formResult?.CLIENT?.questions?.fields?.FLOW?.value?.value,
        });

        Object.assign(formResultUpdate, {
          [index === 0 ? 'WIDTH' : `WIDTH_${index + 1}`]: {
            key: index === 0 ? 'WIDTH' : `WIDTH_${index + 1}`,
            step: 'PRODUCT',
            label: index === 0 ? strings.largura : `${strings.largura} ${index + 1}`,
            type: 'number',
            permissions: { visible: true, editable: true },
            id: formResult.PRODUCT.questions.fields.WIDTH.id,
            display: index === 0 ? true : false,
            value: parseFloat(item),
            readBy: 'user',
            unit: formResult.PRODUCT.questions.fields.WIDTH.unit,
            userType: ['GERDAU', 'CLIENT'],
            flow: formResult?.CLIENT?.questions?.fields?.FLOW?.value?.value,
          },
        });
        if (index === 0) {
          Object.assign(formResultUpdate.WIDTH, {
            conditional: formResult.PRODUCT.questions.fields.WIDTH.conditional,
            detail: formResult.PRODUCT.questions.fields.WIDTH.detail,
            mandatory: formResult.PRODUCT.questions.fields.WIDTH.mandatory,
            order: formResult.PRODUCT.questions.fields.WIDTH.order,
            placeholder: formResult.PRODUCT.questions.fields.WIDTH.placeholder,
            question: formResult.PRODUCT.questions.fields.WIDTH.question,
          });
        }
      });
    }

    if (formData?.data?.LENGTH) {
      formData.data.LENGTH.forEach((item, index) => {
        arrayUpdate.push({
          key: index === 0 ? 'LENGTH' : `LENGTH_${index + 1}`,
          step: 'PRODUCT',
          label: index === 0 ? strings.comprimento : `${strings.comprimento} ${index + 1}`,
          type: 'range',
          permissions: { visible: true, editable: true },
          id: formResult.PRODUCT.questions.fields.LENGTH.id,
          display: index === 0 ? true : false,
          min: item.min,
          max: item.max,
          value: `${item.min} a ${item.max}`,
          readBy: 'user',
          unit: formResult.PRODUCT.questions.fields.LENGTH.unit,
          userType: ['GERDAU', 'CLIENT'],
          flow: formResult?.CLIENT?.questions?.fields?.FLOW?.value?.value,
        });

        Object.assign(formResultUpdate, {
          [index === 0 ? 'LENGTH' : `LENGTH_${index + 1}`]: {
            key: index === 0 ? 'LENGTH' : `LENGTH_${index + 1}`,
            step: 'PRODUCT',
            label: index === 0 ? strings.comprimento : `${strings.comprimento} ${index + 1}`,
            type: 'range',
            permissions: { visible: true, editable: true },
            id: formResult.PRODUCT.questions.fields.LENGTH.id,
            display: index === 0 ? true : false,
            min: item.min,
            max: item.max,
            value: `${item.min} a ${item.max}`,
            readBy: 'user',
            unit: formResult.PRODUCT.questions.fields.LENGTH.unit,
            userType: ['GERDAU', 'CLIENT'],
            flow: formResult?.CLIENT?.questions?.fields?.FLOW?.value?.value,
          },
        });
        if (index === 0) {
          Object.assign(formResultUpdate.LENGTH, {
            conditional: formResult.PRODUCT.questions.fields.LENGTH.conditional,
            detail: formResult.PRODUCT.questions.fields.LENGTH.detail,
            mandatory: formResult.PRODUCT.questions.fields.LENGTH.mandatory,
            order: formResult.PRODUCT.questions.fields.LENGTH.order,
            placeholder: formResult.PRODUCT.questions.fields.LENGTH.placeholder,
            question: formResult.PRODUCT.questions.fields.LENGTH.question,
          });
        }
      });
    }

    if (formData?.data?.THICKNESS) {
      formData.data.THICKNESS.forEach((item, index) => {
        arrayUpdate.push({
          key: index === 0 ? 'THICKNESS' : `THICKNESS_${index + 1}`,
          step: 'PRODUCT',
          label: index === 0 ? strings.espessura : `${strings.espessura} ${index + 1}`,
          type: 'number',
          permissions: { visible: true, editable: true },
          id: formResult.PRODUCT.questions.fields.THICKNESS.id,
          display: index === 0 ? true : false,
          value: parseFloat(item),
          readBy: 'user',
          unit: formResult.PRODUCT.questions.fields.THICKNESS.unit,
          userType: ['GERDAU', 'CLIENT'],
          flow: formResult?.CLIENT?.questions?.fields?.FLOW?.value?.value,
        });

        Object.assign(formResultUpdate, {
          [index === 0 ? 'THICKNESS' : `THICKNESS_${index + 1}`]: {
            key: index === 0 ? 'THICKNESS' : `THICKNESS_${index + 1}`,
            step: 'PRODUCT',
            label: index === 0 ? strings.espessura : `${strings.espessura} ${index + 1}`,
            type: 'number',
            permissions: { visible: true, editable: true },
            id: formResult.PRODUCT.questions.fields.THICKNESS.id,
            display: index === 0 ? true : false,
            value: parseFloat(item),
            readBy: 'user',
            unit: formResult.PRODUCT.questions.fields.THICKNESS.unit,
            userType: ['GERDAU', 'CLIENT'],
            flow: formResult?.CLIENT?.questions?.fields?.FLOW?.value?.value,
          },
        });
        if (index === 0) {
          Object.assign(formResultUpdate.THICKNESS, {
            conditional: formResult.PRODUCT.questions.fields.THICKNESS.conditional,
            detail: formResult.PRODUCT.questions.fields.THICKNESS.detail,
            mandatory: formResult.PRODUCT.questions.fields.THICKNESS.mandatory,
            order: formResult.PRODUCT.questions.fields.THICKNESS.order,
            placeholder: formResult.PRODUCT.questions.fields.THICKNESS.placeholder,
            question: formResult.PRODUCT.questions.fields.THICKNESS.question,
          });
        }
      });
    }

    if (formData?.data?.AMOUNT) {
      formData.data.AMOUNT.forEach((item, index) => {
        arrayUpdate.push({
          key: index === 0 ? 'AMOUNT' : `AMOUNT_${index + 1}`,
          step: 'PRODUCT',
          label: index === 0 ? strings.consumo : `${strings.consumo} ${index + 1}`,
          type: 'number',
          permissions: { visible: true, editable: true },
          id: formResult.PRODUCT.questions.fields.AMOUNT.id,
          display: index === 0 ? true : false,
          value: parseFloat(item),
          readBy: 'user',
          unit: formResult.PRODUCT.questions.fields.AMOUNT.unit,
          userType: ['GERDAU', 'CLIENT'],
          flow: formResult?.CLIENT?.questions?.fields?.FLOW?.value?.value,
        });

        Object.assign(formResultUpdate, {
          [index === 0 ? 'AMOUNT' : `AMOUNT_${index + 1}`]: {
            key: index === 0 ? 'AMOUNT' : `AMOUNT_${index + 1}`,
            step: 'PRODUCT',
            label: index === 0 ? strings.consumo : `${strings.consumo} ${index + 1}`,
            type: 'number',
            permissions: { visible: true, editable: true },
            id: formResult.PRODUCT.questions.fields.AMOUNT.id,
            display: index === 0 ? true : false,
            value: parseFloat(item),
            readBy: 'user',
            unit: formResult.PRODUCT.questions.fields.AMOUNT.unit,
            userType: ['GERDAU', 'CLIENT'],
            flow: formResult?.CLIENT?.questions?.fields?.FLOW?.value?.value,
          },
        });
        if (index === 0) {
          Object.assign(formResultUpdate.AMOUNT, {
            conditional: formResult.PRODUCT.questions.fields.AMOUNT.conditional,
            detail: formResult.PRODUCT.questions.fields.AMOUNT.detail,
            mandatory: formResult.PRODUCT.questions.fields.AMOUNT.mandatory,
            order: formResult.PRODUCT.questions.fields.AMOUNT.order,
            placeholder: formResult.PRODUCT.questions.fields.AMOUNT.placeholder,
            question: formResult.PRODUCT.questions.fields.AMOUNT.question,
          });
        }
      });
    }

    setFormResult(prevValue => ({
      ...prevValue,
      PRODUCT: {
        ...prevValue.PRODUCT,
        questions: {
          ...prevValue.PRODUCT.questions,
          fields: {
            ...prevValue.PRODUCT.questions.fields,
            ...formResultUpdate,
          },
        },
      },
    }));

    handleUpdateDraft(draftQuote.idDraft, arrayUpdate);

    setModalToOpen(null);
    setOpened(false);
    setNumBitolas(1);
    setOpened(false);
  };

  const updateRow = (value, index, field) => {
    additionalDiameters[index][field] = value;
  };

  const handleRemove = (ind: number) => {
    const itemsLeft = additionalDiameters.filter((_, index) => index !== ind);

    itemsLeft.map((item, index) => (item.label = `Bitola ${index + 1}`));

    setAdditionalDiameters(itemsLeft);
  };

  const handleClose = () => {
    setOpened(false);
    setModalToOpen(null);
  };

  const handleNewPointClick = () => {
    setNumBitolas(numBitolas);
    setAdditionalDiameters(prev => [
      ...prev,
      {
        label: `Bitola ${additionalDiameters.length + 1}`,
        amount: null,
        diameter: null,
        side: null,
        thickness: null,
        width: null,
        length: null,
      },
    ]);
  };

  return (
    <Modal preventOverlayClick medium opened={opened} onClose={() => handleClose()}>
      <Form onSubmit={handleSubmit}>
        <Grid fluid>
          <Row>
            <Col xs={12}>
              <H1>{strings.title}</H1>
              <VSeparator />
            </Col>
          </Row>
          <JominyContentWrapperStyled>
            <Row mt mb>
              <Col xs={12}>
                <DiameterRowWrapper>
                  <Table>
                    <TR>
                      <TH></TH>
                      <TH>{strings.bitola} (mm)</TH>
                      {!noLength && <TH>{strings.comprimento} (mm)</TH>}
                      <TH>
                        {strings.consumo} ({strings.consumoTon})
                      </TH>
                      <TH></TH>
                    </TR>

                    {additionalDiameters.map((item: AdditionalDiameterValues, index: number) => (
                      <DiameterRow
                        key={`bitola-${index}`}
                        strings={strings}
                        shape={shape}
                        noLength={noLength}
                        diameter={index === 0 ? formResult?.PRODUCT?.questions?.fields?.DIAMETER?.value : item.diameter}
                        side={index === 0 ? formResult?.PRODUCT?.questions?.fields?.SIDE?.value : item.side}
                        thickness={
                          index === 0 ? formResult?.PRODUCT?.questions?.fields?.THICKNESS?.value : item.thickness
                        }
                        width={index === 0 ? formResult?.PRODUCT?.questions?.fields?.WIDTH?.value : item.width}
                        length={index === 0 ? formResult?.PRODUCT?.questions?.fields?.LENGTH?.value : item.length}
                        amount={index === 0 ? formResult?.PRODUCT?.questions?.fields?.AMOUNT?.value : item.amount}
                        min={item.min}
                        max={item.max}
                        index={index}
                        handleRemove={handleRemove}
                        updateRow={updateRow}
                      />
                    ))}
                  </Table>
                </DiameterRowWrapper>
              </Col>
            </Row>
            <div ref={contentRef} />
          </JominyContentWrapperStyled>
          <Divisor />
          <VSeparator />
          <Hbox vAlign='center'>
            <Hbox.Item>
              <LinkText data-testid='modal-additional-diameter-new-line' noGrow onClick={() => handleNewPointClick()}>
                <FaIcon.Plus size='1x' /> {strings.addMeasure}
              </LinkText>
            </Hbox.Item>

            <Hbox.Separator />

            <Hbox hAlign='flex-end'>
              <Hbox.Item noGrow>
                <Button kind='secondary' expanded onClick={() => handleClose()}>
                  {strings.cancel}
                </Button>
              </Hbox.Item>
              <Hbox.Separator />
              <Hbox.Item noGrow>
                <Button data-testid='modal-additional-diameter-save' kind='primary' expanded type='submit'>
                  {strings.save}
                </Button>
              </Hbox.Item>
            </Hbox>
          </Hbox>
          <VSeparator />
        </Grid>
      </Form>
    </Modal>
  );
};
