import * as React from 'react';
import { Route } from '@app/core/route';
import { AppPath } from '../../app/route-constants';
import { useQuoteGuard } from '../../quote/quote.guard';
import { FormPage } from './form.page';

export const FormRouter: React.FC = () => {
  const quoteAuthorized = useQuoteGuard();

  return (
    <>
      <Route
        exact
        path={AppPath.QUOTE.FORM.DETAIL}
        component={FormPage}
        authorized={quoteAuthorized}
        redirectPath={AppPath.QUOTE.BASE}
      />
    </>
  );
};
