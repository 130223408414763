export function gerarUUID(): string {
  const randomValues = crypto.getRandomValues(new Uint8Array(16));

  randomValues[6] = (randomValues[6] & 0x0f) | 0x40; // Versão 4 (UUID)

  randomValues[8] = (randomValues[8] & 0x3f) | 0x80; // Variante

  return [...randomValues]
    .map((b, i) => {
      const hex = b.toString(16).padStart(2, '0');
      if (i === 4 || i === 6 || i === 8 || i === 10) {
        return `-${hex}`;
      }
      return hex;
    })
    .join('');
}
